export const checkAlreadyLogged = () => {
  const token = sessionStorage.getItem('token')
  if (token !== null) {
    Auth.isAuthenticated = true
    return true
  }

  Auth.isAuthenticated = false
  return false
}

export const Auth = {
  isAuthenticated: false,
  signIn(token) {
    this.isAuthenticated = true
    sessionStorage.setItem('token', token)
  },
  signout() {
    this.isAuthenticated = false
    sessionStorage.removeItem('token')
  },
}

export default Auth
