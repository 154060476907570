import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Auth from './Auth'
import PropTypes from 'prop-types'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={
        /* istanbul ignore next */
        props =>
          Auth.isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: rest.location.state,
              }}
            />
          )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any,
}

const mapStateToProps = /* istanbul ignore next */ state => state

export default connect(mapStateToProps)(PrivateRoute)
