import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendVerificationCode, VERIFICATION_CODE_SENT } from 'actions'
import { Loading } from '@yapay/design-system'

export function focusNext(event) {
  if (event.target.value !== '' && event.target.nextSibling) {
    event.target.nextSibling.focus()
  } else if (event.target.value === '' && event.target.previousSibling) {
    event.target.previousSibling.focus()
  }
}

const inputStyle = {
  width: '45px',
  height: '60px',
  border: '1px solid #e7e7e7',
  borderRadius: '3px',
  padding: '0',
  margin: '0 .5rem',
  font: "300 36px/1 'Roboto', sans-serif",
  textAlign: 'center',
  outline: 'none',
  color: '#868686',
}

export class LoginWithCode extends Component {
  state = { codeSent: false }
  form = React.createRef()

  handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let codes = []
    formData.forEach(value => codes.push(value))
    return codes
  }

  // submitForm = () => this.form.current.dispatchEvent(new Event('submit'))

  submitForm = event => event.preventDefault()

  componentDidMount() {
    this.props.dispatch(sendVerificationCode()).then(resp => {
      resp.type === VERIFICATION_CODE_SENT && this.setState({ codeSent: true })
    })
  }

  render() {
    if (!this.state.codeSent) return <Loading />
    return (
      <div>
        <h2>Identifique-se</h2>
        <p>
          Insira o código de segurança enviado para {this.props.email} para
          continuar.
        </p>
        <form className="my-12" ref={this.form} onSubmit={this.handleSubmit}>
          <div className="flex justify-center">
            <input
              style={inputStyle}
              type="tel"
              onKeyUp={focusNext}
              maxLength="1"
              name="code_1"
            />
            <input
              style={inputStyle}
              type="tel"
              onKeyUp={focusNext}
              maxLength="1"
              name="code_2"
            />
            <input
              style={inputStyle}
              type="tel"
              onKeyUp={focusNext}
              maxLength="1"
              name="code_3"
            />
            <input
              style={inputStyle}
              type="tel"
              onKeyUp={focusNext}
              onChange={this.submitForm}
              maxLength="1"
              name="code_4"
            />
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = /* istanbul ignore next */ state => ({
  email: state.user.email,
})

export default connect(mapStateToProps)(LoginWithCode)
