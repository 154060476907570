import ReactGA from 'react-ga'

const Analytics = props => {
  ReactGA.initialize('UA-126988093-1')
  ReactGA.set({ page: props.location.pathname + props.location.search })
  ReactGA.pageview(props.location.pathname + props.location.search)
  return null
}

export default Analytics
