import React from 'react'
import { connect } from 'react-redux'
import { Icon } from '@yapay/design-system'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import RedirectWithTimer from './RedirectWithTimer'
import imgPhone from 'static/img/phone.svg'

export const PixPaid = props => {
  const { transaction } = props.order

  return (
    <div>
      <RestoreScoll />
      <h2>Recebemos seu pagamento! Transação nº {transaction}</h2>
      {props.redirectUrl && (
        <RedirectWithTimer className="mb-8" to={props.redirectUrl} />
      )}

      <div className="flex items-start my-8">
        <div className="mr-4">
          <Icon
            icon="network"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <div>
          <p className="m-0 mb-2">
            Estamos analisando esta transação e assim que ela for aprovada,
            avisaremos o vendedor para fazer o envio do produto ou liberação do
            serviço.
          </p>

          <p className="m-0">
            Fique tranquilo(a)! Esta análise é apenas para garantir mais
            segurança para você e para o vendedor.
          </p>
        </div>
      </div>

      <div className="flex items-center my-8">
        <div className="mr-4">
          <img
            src={imgPhone}
            width={60}
            height={67}
            className="icon fill-current text-gray-600"
            alt="Ícone de telefone"
          />
        </div>
        <p className="m-0">
          Se for identificada qualquer irregularidade, podemos entrar em contato
          para confirmar alguns dados.
        </p>
      </div>

      <div className="flex items-center">
        <div className="mr-4">
          <Icon
            icon="mail"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Você será informado por e-mail sobre o andamento do pedido.
        </p>
      </div>

      <SellerContact />
    </div>
  )
}

PixPaid.propTypes = {
  order: PropTypes.object,
  redirectUrl: PropTypes.string,
}

export default connect()(PixPaid)
