import React from 'react'
import MediaQuery from 'react-responsive'
import Safe from './Safe'
import Logo from './Logo'
import PropTypes from 'prop-types'
import OrderDetail from 'components/OrderDetail'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { Icon, Button, Loading } from '@yapay/design-system'
import { openCart, closeCart } from 'actions'
import 'static/css/Cart.css'

export const toggleCart = (dispatch, state) => {
  ReactGA.event({
    category: 'Mobile Cart',
    action: 'Clicked',
  })
  return state.closed ? dispatch(openCart()) : dispatch(closeCart())
}

export const Header = props => {
  const { dispatch, cart } = props
  const { orderUndefined } = props.transaction
  return (
    <header className="flex items-center justify-between px-4 pt-4 lg:px-12 lg:pt-8">
      <Logo />
      <MediaQuery query="(min-width: 1200px)">
        <Safe />
      </MediaQuery>
      <div className={`cart xl:hidden cart--${cart.state}`}>
        <i className="cart__icon" onClick={() => toggleCart(dispatch, cart)}>
          <Icon icon="cart" width="32" height="32" fill="#254886" />
        </i>
        <div className={`cart__detail ${cart.closed ? 'hidden' : ''}`}>
          {orderUndefined ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <div>
              <OrderDetail />
              <Button
                color="info"
                className="button-close mb-0 w-full"
                onClick={() => toggleCart(dispatch, cart)}
              >
                Fechar
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func,
  transaction: PropTypes.object,
  cart: PropTypes.object,
}

const mapStateToProps = /* istanbul ignore next */ state => state

export default connect(mapStateToProps)(Header)
