import React from 'react'
import { connect } from 'react-redux'
import { Icon, ButtonGroup } from '@yapay/design-system'
import { Link } from 'react-router-dom'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import UserLogged from 'components/UserLogged'
import RedirectWithTimer from './RedirectWithTimer'

import '../static/css/BalanceError.css'

export const BalanceFail = props => {
  const redirectTime = 10
  const ref = React.useRef()
  const { redirectBalance } = props.history.location

  const handleLogout = () => {
    if (!ref.current) return

    return ref.current.setCounter(redirectTime)
  }

  return (
    <div>
      <RestoreScoll />
      <h2 className="text-blue-500">Não foi possível realizar o pagamento!</h2>
      {props.redirectUrl && (
        <RedirectWithTimer
          className="mb-8"
          to={props.redirectUrl}
          reference={ref}
        />
      )}

      <UserLogged
        {...props}
        onLoggout={handleLogout}
        redirectBalance={redirectBalance}
      />

      <div className="flex items-center mb-8">
        <div className="mr-4 relative">
          <span className="icon-balance-error" />
          <Icon
            icon="transfer"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Não conseguimos processar sua transação neste momento.
        </p>
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-4">
          <svg
            width="61"
            height="63"
            fill="#868686"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.21105 10.908c-.17851-.1143-.27093-.3349-.21315-.5505l2.408-8.98679c.07147-.26673.34564-.42502.61237-.35355.26673.07147.42503.34564.35355.61237L7.129 9.99987h8.101c.2761 0 .5.22383.5.50003 0 .2761-.2239.5-.5.5H6.49998c-.10764 0-.20734-.034-.28893-.0919z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60 31.5C60 48.3447 46.3447 62 29.5 62 16.7239 62 5.78251 54.1445 1.24243 43H.165192C4.75911 54.7093 16.1614 63 29.5 63 46.897 63 61 48.897 61 31.5S46.897 0 29.5 0C20.4144 0 12.2272 3.84656 6.47813 10H7.8667C13.3913 4.44137 21.0438 1 29.5 1 46.3447 1 60 14.6553 60 31.5z"
            />
          </svg>
        </div>
        <p className="m-0">
          Tente realizar o pagamento novamente, verifique se os dados informados
          estão corretos.
        </p>
      </div>
      <ButtonGroup>
        <Link to="/escolha-pagamento" className="button px-0">
          Utilizar outra forma de pagamento
        </Link>

        {props.user.session_token && (
          <Link to="/pagamento-saldo" className="button button--info mt-8 mb-4">
            Tentar novamente
          </Link>
        )}
      </ButtonGroup>

      <SellerContact />
    </div>
  )
}

BalanceFail.propTypes = {
  seller: PropTypes.object,
  redirectUrl: PropTypes.string,
  history: PropTypes.object,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      order: { url_cancel, url_process },
    },
    user,
  } = state

  const redirectUrl = url_cancel || url_process || null

  return { redirectUrl, user }
}

export default connect(mapStateToProps)(BalanceFail)
