import React from 'react'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import { connect } from 'react-redux'
import { Icon, ButtonGroup } from '@yapay/design-system'
import imgBoxRedirect from 'static/img/box-redirect.svg'
import RedirectWithTimer from './RedirectWithTimer'

export const TefDidGenerated = props => {
  return (
    <div>
      <RestoreScoll />
      <h2>
        Quase lá, agora é só efetuar o pagamento! Transação nº{' '}
        {props.order.transaction}
      </h2>
      {props.redirectUrl && (
        <RedirectWithTimer className="mb-8" to={props.redirectUrl} />
      )}

      <Icon
        icon="transfer"
        width="100"
        height="100"
        className="fill-current text-gray-600 mx-auto block mb-8 md:mt-12"
      />
      <p className="text-center" style={{ wordBreak: 'break-word' }}>
        {props.payment.url_payment}
      </p>

      <ButtonGroup className="mx-auto mb-4">
        <a
          href={props.payment.url_payment}
          className="button button--info px-0"
          target="_blank"
          rel="noopener noreferrer"
          data-cy="button-bank-environment"
        >
          Abrir ambiente do Banco
        </a>
      </ButtonGroup>

      <div className="flex items-center my-8">
        <div className="mr-4">
          <img
            src={imgBoxRedirect}
            alt="Redirecionamento"
            width="67"
            height="67"
          />
        </div>
        <p className="m-0">
          Você será direcionado ao ambiente do banco para efetuar o pagamento.
        </p>
      </div>

      <div className="flex items-center">
        <div className="mr-4">
          <Icon
            icon="truck"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          O envio ou serviço somente será realizado após a aprovação da
          transação.
        </p>
      </div>

      <SellerContact />
    </div>
  )
}

TefDidGenerated.propTypes = {
  transaction: PropTypes.object,
  seller: PropTypes.object,
  order: PropTypes.object,
  payment: PropTypes.object,
  barcode: PropTypes.string,
  link: PropTypes.string,
  redirectUrl: PropTypes.string,
}

export default connect()(TefDidGenerated)
