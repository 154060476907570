import {
  SENDING_PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_SENT,
  PASSWORD_RECOVERY_ERROR,
  CHANGE_LOGIN,
  REQUESTS_EXCEEDED,
} from 'actions'

export const textSent =
  'O link para recuperação de senha foi enviado, verifique sua caixa de e-mails'
export const textSending = 'Enviando link para recuperação de senha...'
export const textError = 'Não foi possível enviar a recuperação de senha'
export const textBlocked = 'Tente novamente mais tarde'
export const textDefault = 'Esqueceu sua senha?'

export default (
  state = {
    sending: false,
    sent: false,
    error: false,
    text: textDefault,
  },
  action,
) => {
  switch (action.type) {
    case SENDING_PASSWORD_RECOVERY:
      return {
        ...state,
        text: textSending,
        sending: true,
      }

    case PASSWORD_RECOVERY_SENT:
      return {
        ...state,
        text: textSent,
        sent: true,
        sending: false,
      }

    case PASSWORD_RECOVERY_ERROR:
      return {
        ...state,
        text: textError,
        error: true,
        sending: false,
      }

    case CHANGE_LOGIN:
      return {
        sending: false,
        sent: false,
        error: false,
        text: textDefault,
      }

    case REQUESTS_EXCEEDED:
      return {
        ...state,
        text: textBlocked,
        error: true,
        sending: false,
      }

    default:
      return state
  }
}
