import { STEP_ACTIVE } from 'actions'

export default (state = 1, action) => {
  switch (action.type) {
    case STEP_ACTIVE:
      return action.step
    default:
      return state
  }
}
