import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Auth } from 'components/Auth'
import { signOut, LOGOUT_ERROR } from 'actions'

export class UserLogged extends Component {
  state = {
    errorLogout: false,
    user: this.props.user,
    connectionStatus: 'desconectar',
  }

  signOut(session_token) {
    return this.props.dispatch(signOut(session_token)).then(resp => {
      this.props.onLoggout()

      if (resp.type === LOGOUT_ERROR) {
        return this.setState({
          errorLogout: true,
          connectionStatus: 'falha ao desconectar, tente novamente',
        })
      }

      this.setState({ errorLogout: false, connectionStatus: 'desconectado' })

      ReactGA.event({
        category: 'User',
        action: 'Sign out after payment with balance',
      })

      Auth.signout()
    })
  }

  renderLink() {
    if (this.state.connectionStatus === 'desconectado') {
      return `(${this.state.connectionStatus})`
    }

    return (
      <Link
        to={{
          redirectBalance: true,
        }}
        onClick={() => this.signOut(this.props.user.session_token)}
        className="m-1 no-underline hover:text-blue-600"
        id="signOutBuyer"
      >
        ({this.state.connectionStatus})
      </Link>
    )
  }

  render() {
    if (!this.props.redirectBalance) return null
    if (!this.props.user.email || this.props.user.email === '') return null

    return (
      <div className="flex items-center my-8">
        <p className="mr-2">
          Conta utilizada <b>{this.props.user.email}</b>. {this.renderLink()}
        </p>
      </div>
    )
  }
}

UserLogged.propTypes = {
  user: PropTypes.object,
  redirectBalance: PropTypes.bool,
  onLoggout: PropTypes.func,
}

const mapStateToProps = mapProps => mapProps

export default connect(mapStateToProps)(UserLogged)
