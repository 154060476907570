import { VALIDATE_ANSWER, ANSWER_CORRECT, ANSWER_WRONG } from 'actions'

export function question(state = { isFetching: false }, action) {
  switch (action.type) {
    case VALIDATE_ANSWER:
      return {
        ...state,
        isFetching: true,
        answerCorrect: false,
      }
    case ANSWER_CORRECT:
      return {
        ...state,
        isFetching: false,
        answerCorrect: true,
      }
    case ANSWER_WRONG:
      return {
        ...state,
        isFetching: false,
        answerCorrect: false,
      }
    default:
      return state
  }
}

export default question
