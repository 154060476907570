import { TOGGLE_LINK_LOGO } from 'actions'

const initialState = {
  active: false,
}

export function linkLogo(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LINK_LOGO:
      return {
        ...state,
        active: !state.active,
      }

    default:
      return state
  }
}

export default linkLogo
