import React from 'react'
import PropTypes from 'prop-types'
import Currency from 'components/Currency'

const OrderTypeInvoice = ({ billing, seller }) => (
  <div className="multiple flex flex-col">
    <h2 className="mt-0">Fatura</h2>
    <p className="m-0 text-xl">{seller.name}</p>
    <div className="multiple__content">
      <table className="sidebar-table">
        <tbody>
          {billing.items.map((item, index) => (
            <tr key={index}>
              <td>
                <h4
                  className="m-0 text-lg text-gray-600"
                  style={{ wordBreak: 'break-all' }}
                >
                  {item.billing_plan_name}
                </h4>
                <div>{item.type_entry_name}</div>
              </td>
              <td>
                <h4 className="m-0 text-lg text-right text-gray-600">
                  <Currency value={item.price_due} />
                </h4>
                <p className="m-0 text-xs" style={{ whiteSpace: 'nowrap' }}>
                  {item.date_from} à {item.date_till}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <h2 className="m-0 py-4 text-right sidebar-total">
      Total:{' '}
      <strong>
        <Currency value={billing.total_price} />
      </strong>
    </h2>
  </div>
)

OrderTypeInvoice.propTypes = {
  billing: PropTypes.object.isRequired,
  seller: PropTypes.object.isRequired,
}

export default OrderTypeInvoice
