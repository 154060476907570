import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BackLink from 'components/BackLink'
import { connect } from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { Well, ButtonGroup, Button } from '@yapay/design-system'
import { checkAlreadyLogged } from './Auth'
import { stepActive, newAddress, ADDRESS_UPDATE_ERROR } from 'actions'

export class Address extends Component {
  state = {
    textBtn: 'Continuar',
    showMessageError: false,
  }

  renderBtnChange = hideButton => {
    if (hideButton) return null

    if (!checkAlreadyLogged()) {
      return (
        <a
          href={process.env.REACT_APP_YAPAY_LOGOUT}
          to="#"
          className="absolute top-0 right-0 px-4 py-2 no-underline"
        >
          <b className="text-gray-600 text-xs">ALTERAR</b>
        </a>
      )
    }

    return (
      <Link
        to={{
          pathname: '/altera-cep',
          state: { changeAddress: true },
        }}
        className="absolute top-0 right-0 px-4 py-2 no-underline"
      >
        <b className="text-gray-600 text-xs">ALTERAR</b>
      </Link>
    )
  }

  renderAddress = address => {
    return (
      <div>
        {this.props.continueWithPost === true ? (
          <p>
            Para realizar seu pagamento com segurança utilizando o intermediador{' '}
            <span>Vindi</span>, verifique se os campos abaixo estão corretos,
            caso seja necessário alguma alteração retorne ao site de origem:
          </p>
        ) : (
          <p className="mt-0">
            Identificamos que você já possui uma conta na <span>Vindi</span>,
            verifique se seu endereço está correto para continuar.
          </p>
        )}
        <Well className="mt-0 mb-4 p-4 relative" pre>
          <div>{address.zip_code}</div>
          <div>
            {address.street}
            {address.number ? ', ' + address.number : ''}
          </div>
          <div>{address.neighborhood}</div>
          <div>
            {address.city}
            {address.state ? ' - ' + address.state : ''}
          </div>
          <div>{address.complement}</div>
          {this.renderBtnChange(this.props.hideButton)}
        </Well>
      </div>
    )
  }

  updateAddress = async () => {
    const { dispatch, history, address, token } = this.props
    this.setState({ textBtn: 'Carregando...' })

    const response = await dispatch(newAddress({ ...address, token }))
    if (response.type === ADDRESS_UPDATE_ERROR) {
      return this.setState({ textBtn: 'Continuar', showMessageError: true })
    }

    history.push('/escolha-pagamento')
  }

  renderMessageError = () => {
    if (!this.state.showMessageError) return null

    return <p className="text-red-400 text-center">{this.props.errorMessage}</p>
  }

  renderButton = () => {
    if (this.props.continueWithPost) {
      return (
        <Button color="info" className="mb-4" onClick={this.updateAddress}>
          {this.state.textBtn}
        </Button>
      )
    }

    return (
      <Link
        to="/escolha-pagamento"
        className="button button--info mb-4"
        data-cy="continue-address"
      >
        Continuar
      </Link>
    )
  }

  componentDidMount() {
    this.props.dispatch(stepActive(2))
  }

  render() {
    const { address, hideButton } = this.props
    if (!Object.keys(address).length) return <Redirect to="/nao-encontrado" />

    return (
      <React.Fragment>
        <h2 className="mb-2">Confirme seu endereço</h2>
        <div className="relative">{this.renderAddress(address)}</div>
        {this.renderMessageError()}
        <ButtonGroup
          responsive
          className={`items-start ${hideButton ? 'justify-center' : ''}`}
        >
          {!hideButton && (
            <BackLink className="button text-center px-0">Voltar</BackLink>
          )}
          {this.renderButton()}
        </ButtonGroup>
      </React.Fragment>
    )
  }
}

Address.propTypes = {
  address: PropTypes.object.isRequired,
}

const mapStateToProps = /* istanbul ignore next */ props => {
  const {
    user: {
      name,
      email,
      cpf,
      phone,
      zip_code,
      street,
      number,
      neighborhood,
      city,
      state,
      complement,
      continueWithPost,
      errorMessage,
      hideChangeButton,
    },
    transaction: {
      type,
      order: { token },
    },
  } = props

  let address = {}
  let hideButton = false

  if (type === 'o' || hideChangeButton === true) {
    hideButton = true
  }

  if (zip_code) address.zip_code = zip_code
  if (street) address.street = street
  if (number) address.number = number
  if (neighborhood) address.neighborhood = neighborhood
  if (city) address.city = city
  if (state) address.state = state
  if (complement) address.complement = complement
  if (name) address.name = name
  if (email) address.email = email
  if (cpf) address.cpf = cpf
  if (phone) address.phone = phone

  return { address, hideButton, token, continueWithPost, errorMessage }
}

export default withRouter(connect(mapStateToProps)(Address))
