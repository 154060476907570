import {
  REQUEST_INFO,
  RECEIVE_INFO,
  SET_ORDER,
  ORDER_UNDEFINED,
  BILLET_DID_GENERATE,
  BILLET_ERROR,
  TRANSFER_ERROR,
  CARD_APPROVED,
  CARD_PROCESSING,
  CARD_DENIED,
  PAY_BALANCE_ERROR,
} from 'actions'

const initialState = {
  orderUndefined: true,
  isFetching: false,
  paymentRetry: false,
  type: 't',
  order: {
    token: '',
    available_payment_methods: [],
  },
  seller: {
    token: '',
  },
  buyer: {
    email: '',
  },
}

export function transaction(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER:
      if (state.order.token !== action.payload.token) {
        return {
          ...initialState,
          type: action.payload.type,
          order: {
            ...initialState.order,
            token: action.payload.token,
          },
        }
      }

      return {
        ...state,
        type: action.payload.type,
        order: {
          ...state.order,
          token: action.payload.token,
        },
      }

    case REQUEST_INFO:
      return {
        ...state,
        isFetching: true,
      }

    case RECEIVE_INFO:
      return {
        ...state,
        ...action.data,
        seller: {
          ...state.seller,
          ...action.data.seller,
        },
        order: {
          ...state.order,
          ...action.data.order,
          status: action.data.order.status_tag,
        },
        orderUndefined: false,
        isFetching: false,
      }

    case ORDER_UNDEFINED:
      return {
        ...state,
        orderUndefined: true,
        isFetching: false,
      }

    case BILLET_DID_GENERATE:
      return {
        ...state,
        order: {
          ...state.order,
          status: action.data.status,
        },
      }

    case BILLET_ERROR:
      return {
        ...state,
        paymentRetry: true,
        order: {
          ...state.order,
          ...action.data,
        },
      }

    case TRANSFER_ERROR:
      return {
        ...state,
        paymentRetry: true,
        order: {
          ...state.order,
          ...action.data,
        },
      }

    case CARD_PROCESSING:
      return {
        ...state,
        order: {
          ...state.order,
          transaction_id: action.data.transaction_id,
        },
      }

    case CARD_APPROVED:
      return {
        ...state,
        order: {
          ...state.order,
          transaction_id: action.data.transaction_id,
        },
      }

    case CARD_DENIED:
      return {
        ...state,
        paymentRetry: true,
        order: {
          ...state.order,
          ...action.data,
        },
      }

    case PAY_BALANCE_ERROR:
      return {
        ...state,
        paymentRetry: true,
        order: {
          ...state.order,
          ...action.data,
        },
      }

    default:
      return state
  }
}

export default transaction
