import React from 'react'
import ReactGA from 'react-ga'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import Clipboard from 'react-clipboard.js'
import MediaQuery from 'react-responsive'
import SellerContact from 'components/SellerContact'
import { connect } from 'react-redux'
import { Alert, Icon, ButtonGroup } from '@yapay/design-system'
import RedirectWithTimer from './RedirectWithTimer'
import moment from 'moment'

export function copySuccess(event) {
  ReactGA.event({
    category: 'Pix Payment',
    action: 'Copied Barcode',
  })

  event.trigger.textContent = event.trigger.dataset.successCopy
  setTimeout(() => {
    event.trigger.textContent = event.trigger.dataset.defaultText
  }, 2000)
}

export const PixDidGenerated = props => {
  const expirationDateFormatted = React.useMemo(
    () => moment(props.payment.qrcode_expiration).format('DD/MM/YYYY - HH:mm'),
    [props.payment.qrcode_expiration],
  )

  return (
    <div>
      <RestoreScoll />
      <h2 data-cy="message-pix-generated">
        QR Code Pix gerado com sucesso! Transação nº {props.order.transaction}
      </h2>

      <p className="my-6">
        Agora é só fazer o pagamento no aplicativo do seu banco.
      </p>

      {props.redirectUrl && (
        <RedirectWithTimer to={props.redirectUrl} start={120} />
      )}

      <div className="flex align-item justify-center my-8">
        <div className="p-4 shadow-2xl rounded-lg">
          <object
            type="image/svg+xml"
            data={props.payment.qrcode_path}
            className="w-56 h-56"
            aria-label="QRCode Pix"
          />
        </div>
      </div>

      <div className="my-8">
        <p className="m-0 mb-1 text-center">Vencimento:</p>
        <p className="m-0 text-black font-semibold text-center">
          {expirationDateFormatted}
        </p>
      </div>

      <MediaQuery query="(min-width: 992px)">
        <ButtonGroup className="mx-auto">
          <Clipboard
            data-clipboard-text={props.payment.qrcode_original_path}
            data-default-text="Copiar código"
            data-success-copy="Copiado!"
            className="button button--info"
            onSuccess={copySuccess}
          >
            Copiar código
          </Clipboard>
        </ButtonGroup>
      </MediaQuery>

      <MediaQuery query="(max-width: 991px)">
        <Clipboard
          data-clipboard-text={props.payment.qrcode_original_path}
          data-default-text="Copiar código"
          data-success-copy="Copiado!"
          className="button button--info mb-4 w-full"
          onSuccess={copySuccess}
        >
          Copiar código
        </Clipboard>
      </MediaQuery>

      <div className="my-10">
        <Alert color="info" withIcon>
          <div className="ml-2 text-sm text-gray-500">
            <span className="font-semibold">Atenção: </span>a razão social e
            CNPJ da Vindi Pagamentos aparecerão como destinatário desta
            transação pois somos a empresa contratada pelo seu vendedor para
            receber os pagamentos. O valor pago por você será repassado para o
            vendedor.
          </div>
        </Alert>
      </div>

      <div className="flex items-center my-6">
        <div className="mr-4 relative">
          <Icon
            icon="days"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          A confirmação do pagamento será feita de forma automática.
        </p>
      </div>

      <div className="flex items-center">
        <div className="mr-4">
          <Icon
            icon="truck"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          O envio ou serviço somente será realizado após a aprovação da
          transação.
        </p>
      </div>

      <SellerContact />
    </div>
  )
}

PixDidGenerated.propTypes = {
  transaction: PropTypes.object,
  seller: PropTypes.object,
  order: PropTypes.object,
  payment: PropTypes.object,
  redirectUrl: PropTypes.string,
  expirationDateFormatted: PropTypes.string,
}

export default connect()(PixDidGenerated)
