import React from 'react'
import { connect } from 'react-redux'
import { Icon } from '@yapay/design-system'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import RedirectWithTimer from './RedirectWithTimer'

export const BilletPaid = props => {
  const { transaction } = props.order

  return (
    <div>
      <RestoreScoll />
      <h2>Pagamento realizado com sucesso! Transação nº {transaction}</h2>
      {props.redirectUrl && (
        <RedirectWithTimer className="mb-8" to={props.redirectUrl} />
      )}

      <div className="flex items-center my-8">
        <div className="mr-4">
          <Icon
            icon="transfer"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Seu pagamento foi recebido com sucesso pela nossa equipe.
        </p>
      </div>

      <div className="flex items-center my-8">
        <div className="mr-4">
          <Icon
            icon="truck"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Já informamos o vendedor que seu pagamento foi aprovado e ele pode
          enviar o produto.
        </p>
      </div>

      <div className="flex items-center">
        <div className="mr-4">
          <Icon
            icon="mail"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Você será informado por e-mail sobre o andamento do pedido.
        </p>
      </div>

      <SellerContact />
    </div>
  )
}

BilletPaid.propTypes = {
  order: PropTypes.object,
  redirectUrl: PropTypes.string,
}

export default connect()(BilletPaid)
