import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Loading } from '@yapay/design-system'
import OrderDetail from 'components/OrderDetail'

export class Sidebar extends Component {
  renderEmail = () => {
    return (
      <p className="sidebar-email mb-8">
        {this.props.email && (
          <React.Fragment>
            Você está pagando com <br />
            <strong>{this.props.email}</strong>
          </React.Fragment>
        )}
      </p>
    )
  }

  render() {
    const { orderUndefined, isFetching, seller } = this.props
    if (orderUndefined && !isFetching) {
      return (
        <MediaQuery query="(min-width: 1200px)">
          <aside className="px-8 flex flex-col items-center justify-center">
            <h3 className="m-0">Oops! :(</h3>
            <p>Nenhuma transação encontrada</p>
          </aside>
        </MediaQuery>
      )
    }

    if (seller.token === '') {
      return (
        <MediaQuery query="(min-width: 1200px)">
          <aside className="px-8 pt-32 flex items-center justify-center">
            <Loading />
          </aside>
        </MediaQuery>
      )
    }

    return (
      <MediaQuery query="(min-width: 1200px)">
        <aside className="px-8 pt-12">
          {this.renderEmail()}
          <OrderDetail />
        </aside>
      </MediaQuery>
    )
  }
}

Sidebar.propTypes = {
  orderUndefined: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  seller: PropTypes.object,
  email: PropTypes.string,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const { orderUndefined, isFetching, seller } = state.transaction
  const email = state.transaction.buyer.email || state.user.email || ''
  return { orderUndefined, isFetching, seller, email }
}

export default withRouter(connect(mapStateToProps)(Sidebar))
