import React from 'react'
import ReactGA from 'react-ga'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import Clipboard from 'react-clipboard.js'
import MediaQuery from 'react-responsive'
import SellerContact from 'components/SellerContact'
import { connect } from 'react-redux'
import { Icon, ButtonGroup } from '@yapay/design-system'
import RedirectWithTimer from './RedirectWithTimer'
import imgBolepixConfirmation from 'static/img/bolepix-confirmation.svg'

export function copySuccess(event) {
  ReactGA.event({
    category: 'Bolepix Payment',
    action: 'Copied Barcode',
  })

  event.trigger.textContent = event.trigger.dataset.successCopy
  setTimeout(() => {
    event.trigger.textContent = event.trigger.dataset.defaultText
  }, 2000)
}

export const handleCopyValue = value => {
  navigator.clipboard.writeText(value)
}

export const BolepixDidGenerated = props => {
  return (
    <div>
      <RestoreScoll />
      <h2 data-cy="message-bolepix-generated">
        Bolepix gerado com sucesso! Transação nº {props.order.transaction}
      </h2>
      {props.redirectUrl && <RedirectWithTimer to={props.redirectUrl} />}

      <div className="flex align-item justify-center my-8">
        <div className="p-4 shadow-2xl rounded-lg">
          <object
            type="image/svg+xml"
            data={props.payment.qrcode_path}
            className="w-56 h-56"
            aria-label="QRCode Bolepix"
          />
        </div>
      </div>

      <MediaQuery query="(min-width: 992px)">
        <p
          style={{
            border: '1px solid #e7e7e7',
            borderRadius: '8px',
            overflowY: 'scroll',
            wordBreak: 'break-word',
            width: 'auto',
            height: '50px',
          }}
          className="mb-4"
          onClick={() => handleCopyValue(props.payment.qrcode_original_path)}
        >
          {props.payment.qrcode_original_path}
        </p>
        <ButtonGroup responsive>
          <a
            href={props.payment.url_payment}
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visualizar Bolepix
          </a>
          <Clipboard
            data-clipboard-text={props.payment.qrcode_original_path}
            data-default-text="Copiar código"
            data-success-copy="Copiado!"
            className="button button--info p-0"
            onSuccess={copySuccess}
          >
            Copiar código
          </Clipboard>
        </ButtonGroup>
      </MediaQuery>

      <MediaQuery query="(max-width: 991px)">
        <p
          style={{
            border: '1px solid #e7e7e7',
            borderRadius: '8px',
            overflowY: 'scroll',
            wordBreak: 'break-word',
            width: 'auto',
            height: '50px',
          }}
          className="mb-4 w-full"
          onClick={() => handleCopyValue(props.payment.qrcode_original_path)}
        >
          {props.payment.qrcode_original_path}
        </p>
        <Clipboard
          data-clipboard-text={props.payment.qrcode_original_path}
          data-default-text="Copiar código"
          data-success-copy="Copiado!"
          className="button button--info mb-4 w-full"
          onSuccess={copySuccess}
        >
          Copiar código
        </Clipboard>
        <a
          href={props.payment.url_payment}
          className="button w-full"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visualizar Bolepix
        </a>
      </MediaQuery>

      <div className="flex items-center my-6">
        <div className="mr-4 relative">
          <figure className="flex ml-0 mt-0 mb-0 mr-1">
            <img
              src={imgBolepixConfirmation}
              alt="Bolepix"
              width="67"
              height="67"
            />
          </figure>
        </div>
        <p className="m-0">
          Pagamento instantâneo com Bolepix, <wbr />
          não precisa esperar!
        </p>
      </div>

      <div className="flex items-center">
        <div className="mr-4">
          <Icon
            icon="truck"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          O envio ou serviço somente será realizado após a aprovação da
          transação.
        </p>
      </div>

      <SellerContact />
    </div>
  )
}

BolepixDidGenerated.propTypes = {
  transaction: PropTypes.object,
  seller: PropTypes.object,
  order: PropTypes.object,
  payment: PropTypes.object,
  link: PropTypes.string,
  redirectUrl: PropTypes.string,
}

export default connect()(BolepixDidGenerated)
