import React from 'react'
import { connect } from 'react-redux'
import { Icon, Button, ButtonGroup } from '@yapay/design-system'
import { Link } from 'react-router-dom'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import RedirectWithTimer from './RedirectWithTimer'

export const CardFail = props => {
  const { tryAgain, billetAvailable, transaction, redirectUrl } = props

  return (
    <div>
      <RestoreScoll />
      <h2 className="text-blue-500 mb-8">
        Pagamento não autorizado! Transação nº {transaction.order.transaction}
      </h2>
      {redirectUrl && <RedirectWithTimer className="mb-8" to={redirectUrl} />}

      <div className="flex items-center mb-8">
        <div className="mr-4">
          <Icon
            icon="card-failure"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">Não foi possível processar seu pagamento!</p>
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-4">
          <Icon
            icon="card-balance"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          {transaction.order.message
            ? transaction.order.message
            : 'Verifique junto ao emissor do cartão e tente realizar o pagamento novamente.'}
        </p>
      </div>

      <ButtonGroup className="mx-auto">
        <Button color="info" className="px-0 mb-4" onClick={tryAgain}>
          Tentar novamente
        </Button>
        {billetAvailable ? (
          <Link to="/pagamento-boleto" className="button text-sm px-0">
            Pagar com boleto bancário
          </Link>
        ) : null}
      </ButtonGroup>

      <SellerContact />
    </div>
  )
}

CardFail.propTypes = {
  transaction: PropTypes.object,
  tryAgain: PropTypes.func.isRequired,
  billetAvailable: PropTypes.bool,
  redirectUrl: PropTypes.string,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      order: { url_cancel, url_process },
    },
  } = state

  const redirectUrl = url_cancel || url_process || null

  return { ...state, redirectUrl }
}

export default connect(mapStateToProps)(CardFail)
