import React from 'react'
import PropTypes from 'prop-types'
import Currency from 'components/Currency'

const OrderTypeCharge = ({ order, seller }) => (
  <React.Fragment>
    <h2 className="mt-0">Solicitação de Pagamento</h2>
    <p className="m-0 text-lg">
      Enviada por <span>{seller.name}</span>
    </p>
    <p className="text-justify" style={{ wordBreak: 'break-all ' }}>
      {order.products[0].description}
    </p>
    <h2 className="text-right">
      Valor:{' '}
      <strong>
        <Currency value={order.total_price} />
      </strong>
    </h2>
  </React.Fragment>
)

OrderTypeCharge.propTypes = {
  order: PropTypes.object.isRequired,
  seller: PropTypes.object.isRequired,
}

export default OrderTypeCharge
