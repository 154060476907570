import PropTypes from 'prop-types'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return null
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.object,
  children: PropTypes.object,
}

export default withRouter(ScrollToTop)
