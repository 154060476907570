import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import Cards from 'react-credit-cards'
import CardFail from 'components/CardFail'
import OrderDetail from 'components/OrderDetail'
import ReactGA from 'react-ga'
import { initFingerPrint, getFingerPrint } from 'components/FingerPrint'
import { connect } from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { payWithCard } from 'actions'
import 'react-credit-cards/es/styles-compiled.css'
import 'static/css/CardPayment.css'
import {
  InputCreditCard,
  InputCreditCardDate,
  InputCvv,
  InputName,
  Select,
  Button,
  ButtonGroup,
  Well,
} from '@yapay/design-system'
import Recaptcha from 'components/Recaptcha'
import RedirectWithTimer from './RedirectWithTimer'

const initialState = {
  number: '',
  name: 'Seu nome',
  expiry: '',
  cvc: '',
  focused: '',
  issuer: '',
  btnText: 'Pagar',
  payment_method: 'mastercard',
  tokenRecaptcha: '',
  dirtyPayButton: false,
  split_number: '',
  disabledSplitNumber: false,
}

export class CardPayment extends Component {
  state = initialState

  handleInputFocus = ({ target }) => {
    this.setState({ focused: target.name })
  }

  handleBlurCard = value => {
    if (value !== 'none') {
      this.setState({ payment_method: value }, () => this.checkSplitNumber())
    }
  }

  handleClickCard = value => {
    this.setState(
      {
        payment_method: value,
        issuer: value,
      },
      () => this.checkSplitNumber(),
    )
  }

  handleBlurCVV = () => {
    this.setState({ focused: 'name' })
  }

  handleCardNumber = ({ target }) => {
    this.setState({
      number: target.value.replace(/ /g, ''),
      card_number: target.value.replace(/ /g, ''),
    })
  }

  handleExpiry = ({ target }) => {
    this.setState({
      expiry: target.value,
      card_expiration_month: target.value.split('/')[0],
      card_expiration_year: '20' + target.value.split('/')[1],
    })
  }

  handleName = ({ target }) => {
    this.setState({
      name: target.value,
      card_name: target.value,
    })
  }

  handleCVV = ({ target }) => {
    this.setState({ cvc: target.value, card_cvv: target.value })
  }

  handleSelect = ({ target }) => {
    this.setState({
      split_number: target.value,
    })
  }

  submitForm = event => {
    this.setState({ dirtyPayButton: true })
    event.preventDefault()

    if (!this.state.tokenRecaptcha) {
      return
    }

    const data = {
      // from local state
      card_cvv: this.state.card_cvv,
      card_expiration_month: this.state.card_expiration_month,
      card_expiration_year: this.state.card_expiration_year,
      card_name: this.state.card_name,
      card_number: this.state.card_number,
      split_number: this.state.split_number,
      payment_method: this.state.payment_method,

      // from props
      token: this.props.token,
      seller_token: this.props.sellerToken,
      address_token: this.props.addressToken,
      order_token: this.props.orderToken,
      transaction_token: this.props.transactionToken,
      payment_retry: this.props.paymentRetry,
      email: this.props.email,
      finger_print: getFingerPrint(),
      token_recaptcha: this.state.tokenRecaptcha,
    }

    if (this.props.transaction && this.props.transaction.type === 't') {
      data.customer = this.props.customer
    }

    if (
      data.card_number === '' &&
      data.card_name === '' &&
      data.card_expiration_month === '' &&
      data.card_expiration_year === '' &&
      data.payment_method.length === 0
    ) {
      return null
    }

    this.setState({ btnText: 'Processando...' })

    return this.props.dispatch(payWithCard(data)).then(resp => {
      this.setState({ btnText: 'Pagar' })
      this.processApiResponse(resp)
      return resp
    })
  }

  processApiResponse = resp => {
    if (resp === null) {
      ReactGA.event({
        category: 'Card Payment',
        action: 'Failed to process',
      })

      this.setState({ showMessageError: true })
      return null
    }

    if (resp.data.status === 'denied') {
      ReactGA.event({
        category: 'Card Payment',
        action: 'Payment Denied',
      })

      this.setState({
        denied: true,
        ...initialState,
        ...resp.data,
      })

      return false
    }

    this.props.history.replace(`/pagamento/${resp.data.transaction_token}`)
    return true
  }

  tryAgain = () => {
    ReactGA.event({
      category: 'Card Payment',
      action: 'Try to pay again',
    })
    this.setState({ denied: false, showMessageError: false })
  }

  formatSplit = value =>
    parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

  hasInterest = (a, b) => (a < b ? 'com juros' : 'sem juros')

  checkSplitNumber = () => {
    if (!this.props.availableMethods) {
      return
    }

    const paymentMethod = this.props.availableMethods.find(
      ({ name }) => name === this.state.payment_method,
    )
    const hasOnlyOneSplitOption =
      paymentMethod && paymentMethod.split.length === 1
    const splitNumber = hasOnlyOneSplitOption ? '1' : ''

    this.setState({
      split_number: splitNumber,
      disabledSplitNumber: !!paymentMethod && hasOnlyOneSplitOption,
    })
  }

  renderSplitOptions = () => {
    const { availableMethods, totalPrice } = this.props

    // filtra o split com base no pagamento do estado
    const splitByFlag = availableMethods.filter(({ name }) => {
      return name === this.state.payment_method
    })[0]

    // define um valor padrão para o split caso esteja vazio
    if (splitByFlag === undefined) return null

    return splitByFlag.split.map(
      ({ split, value_split, value_transaction }) => {
        const total_item = parseFloat(value_transaction, 10)
        const tax = this.hasInterest(totalPrice, total_item)
        return (
          <option value={split} key={split}>
            {split}x de {this.formatSplit(value_split)} (
            {this.formatSplit(value_transaction) + ' ' + tax})
          </option>
        )
      },
    )
  }

  renderCrediCard = () => {
    const { name, number, expiry, cvc, focused, issuer } = this.state
    return (
      <MediaQuery query="(min-width: 768px)">
        <div className="ml-8">
          <Cards
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            issuer={issuer}
            preview={true}
          />
        </div>
      </MediaQuery>
    )
  }

  renderCardFail = () => {
    const { billetAvailable, transaction, message } = this.props

    return (
      <CardFail
        transaction={transaction}
        message={message}
        billetAvailable={billetAvailable}
        tryAgain={this.tryAgain}
      />
    )
  }

  componentDidMount() {
    initFingerPrint()
    this.checkSplitNumber()
  }

  render() {
    ReactGA.event({
      category: 'Choose Payment',
      action: 'Card',
    })

    if (this.props.orderUndefined) {
      return <Redirect to="/nao-encontrado" />
    }

    if (this.state.denied) {
      return this.renderCardFail()
    }

    return (
      <form
        onSubmit={this.submitForm}
        id="payment-form"
        data-yapay="payment-form"
      >
        <h2>Preencha os campos com os dados do cartão</h2>
        <div className="flex">
          <div className="flex-1">
            <InputCreditCard
              label="Número do Cartão"
              id="cardNumber"
              name="number"
              onFocus={this.handleInputFocus}
              onChange={this.handleCardNumber}
              onBlur={this.handleBlurCard}
              onClick={this.handleClickCard}
              thumbs="expanded"
              availableCC={this.props.cardFlags}
              required
            />
            <div className="card-thumbs" />
            <InputName
              label="Nome do titular"
              id="card-name"
              name="name"
              type="text"
              autoComplete="off"
              onFocus={this.handleInputFocus}
              onChange={this.handleName}
              maxLength={30}
              required
              data-cy="card-name"
            />
            <InputCreditCardDate
              label="Data de vencimento"
              id="card-date"
              name="expiry"
              onFocus={this.handleInputFocus}
              onChange={this.handleExpiry}
              required
            />
            <InputCvv
              label="Código CVV"
              id="card-cvv"
              name="cvc"
              onFocus={this.handleInputFocus}
              onChange={this.handleCVV}
              onBlur={this.handleBlurCVV}
              required
            />
            <Select
              label="Escolha o parcelamento"
              id="payment-option"
              value={this.state.split_number}
              disabled={this.state.disabledSplitNumber}
              onChange={this.handleSelect}
              required
            >
              <option />
              {this.renderSplitOptions()}
            </Select>
          </div>
          {this.renderCrediCard()}
        </div>

        <MediaQuery query="(max-width: 767px)">
          <Well className="p-4 md:p-4 -mx-4" plain>
            <OrderDetail />
          </Well>
        </MediaQuery>

        <div className="mb-4">
          <Recaptcha
            onChange={tokenRecaptcha => this.setState({ tokenRecaptcha })}
            invalid={!this.state.tokenRecaptcha && this.state.dirtyPayButton}
            errorMessage="Confirme o reCAPTCHA para continuar"
          />
        </div>

        {this.state.showMessageError && (
          <p className="text-red-400 text-center">
            Não foi possível realizar o pagamento
          </p>
        )}

        {this.state.showMessageError && this.props.redirectUrl && (
          <RedirectWithTimer
            className="text-center -mt-4"
            to={this.props.redirectUrl}
          />
        )}

        <ButtonGroup responsive className="items-center">
          <Link to="/escolha-pagamento" className="button text-center">
            Voltar
          </Link>
          <Button className="sm:w-64" color="info" data-cy="button-pay">
            {this.state.btnText}
          </Button>
        </ButtonGroup>
      </form>
    )
  }
}

CardPayment.propTypes = {
  submitForm: PropTypes.func,
  transaction: PropTypes.object,
  login: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  dispatch: PropTypes.func,
  redirectUrl: PropTypes.string,
  customer: PropTypes.object,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    user: { address_token: addressToken, email },
    transaction: {
      paymentRetry,
      orderUndefined,
      order: {
        message,
        token,
        available_payment_methods: paymentMethods,
        total_price: totalPrice,
        max_split_transaction: maxSplit,
        order_token: orderToken,
        transaction_token: transactionToken,
        url_cancel,
        url_process,
      },
      seller: { token: sellerToken },
    },
  } = state
  let availableMethods = null
  let cardFlags = null
  const transaction = state.transaction
  const billetAvailable = paymentMethods.some(item => item.type === 'billet')

  if (paymentMethods.length) {
    availableMethods = paymentMethods.filter(item => item.type === 'card')
    availableMethods = availableMethods[0].values
    cardFlags = availableMethods.map(({ name }) => name)
  }

  const redirectUrl = url_cancel || url_process || null
  const customer = {
    name: state.user.name,
    company_name: state.user.company_name,
    email: state.user.email,
    cpf: state.user.cpf,
    cnpj: state.user.cnpj,
    zip_code: state.user.zip_code,
    street: state.user.street,
    number: state.user.number,
    neighborhood: state.user.neighborhood,
    city: state.user.city,
    state: state.user.state,
    phone: state.user.phone,
  }

  return {
    token,
    orderToken,
    transactionToken,
    redirectUrl,
    availableMethods,
    cardFlags,
    totalPrice,
    orderUndefined,
    transaction,
    message,
    maxSplit,
    sellerToken,
    addressToken,
    paymentRetry,
    email,
    billetAvailable,
    customer,
  }
}

export default withRouter(connect(mapStateToProps)(CardPayment))
