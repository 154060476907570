import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export class SellerContact extends Component {
  render() {
    const { name, email, phone } = this.props.transaction.seller

    if (!email && !phone) return null

    return (
      <section
        className="seller-contact well p-4"
        style={{ wordBreak: 'break-all ' }}
      >
        <h5 className="m-0">Dúvidas? Contate o vendedor</h5>
        <div className="text-gray-600">{name}</div>
        {email ? <a href={`mailto:${email}`}>{email}</a> : null}
        {phone ? <div className="text-gray-600">{phone}</div> : null}
      </section>
    )
  }
}

SellerContact.propTypes = {
  transaction: PropTypes.object.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(SellerContact)
