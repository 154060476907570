import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Auth from 'components/Auth'
import RestoreScoll from 'components/RestoreScroll'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {
  InputText,
  InputLocked,
  Button,
  ButtonGroup,
  Select,
} from '@yapay/design-system'
import {
  stepActive,
  signUp,
  newAddress,
  changeUser,
  LOGGED_IN,
  ADDRESS_UPDATE_ERROR,
  SESSION_EXPIRED,
} from 'actions'

export class Address extends Component {
  states = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ]

  submitForm = event => {
    event.preventDefault()
    const {
      dispatch,
      history,
      user,
      login: { session_token, registered },
      transaction: {
        seller: { token: seller_token },
        order: { token },
      },
    } = this.props

    if (registered && user.addressChanged) {
      const data = { session_token, token, ...user }
      return dispatch(newAddress(data)).then(resp => {
        if (resp.type === ADDRESS_UPDATE_ERROR) return null

        Auth.signout()

        if (resp.type === SESSION_EXPIRED) {
          dispatch(stepActive(1))
          return history.push('/login')
        }

        dispatch(stepActive(3))
        history.push('/escolha-pagamento')
      })
    }

    if (registered && user.addressChanged === false) {
      return history.push('/escolha-pagamento')
    }

    const data = { seller_token, token, ...user }
    if (user.continueWithPost && user.registered) {
      this.updateAddress(data)
    } else {
      history.push('/escolha-pagamento')
    }
  }

  updateAddress = async data => {
    const { dispatch, history } = this.props
    await dispatch(newAddress(data))
    Auth.signout()
    history.push('/escolha-pagamento')
  }

  signUpUser = async data => {
    const { dispatch, history } = this.props
    const resp = await dispatch(signUp(data))

    if (resp.type === LOGGED_IN) {
      Auth.signout()
      history.push('/escolha-pagamento')
    }
  }

  componentDidMount() {
    this.props.dispatch(stepActive(2))
  }

  changeField = event => {
    const { target } = event
    this.props.dispatch(changeUser({ [target.name]: target.value }))
  }

  render() {
    const {
      city,
      complement,
      neighborhood,
      number,
      state,
      street,
      zip_code,
      text,
      errorMessage,
      showErrorMessage,
      lockCep,
    } = this.props.user

    return (
      <div>
        <RestoreScoll />
        <h2>Complete seu endereço</h2>
        <form onSubmit={this.submitForm}>
          {lockCep ? (
            <InputText
              label="CEP"
              value={zip_code}
              name="zip_code"
              id="zip_code"
              disabled
            />
          ) : (
            <InputLocked
              label="CEP"
              value={zip_code}
              name="zip_code"
              id="zip_code"
              component={
                <Link
                  to={`/cep`}
                  className="input-locked__button hover:text-gray-900"
                >
                  Alterar
                </Link>
              }
            />
          )}
          <div className="md:flex">
            <InputText
              type="text"
              label="Cidade"
              value={city}
              id="city"
              name="city"
              className="flex-1"
              onChange={this.changeField}
              highlightRequired={city ? false : true}
              maxLength={50}
              required
            />
            <Select
              label="Estado"
              className="md:ml-4 md:w-1/4"
              id="state"
              name="state"
              value={state ? state.toLowerCase() : ''}
              onChange={this.changeField}
              required
            >
              <option />
              {this.states.map(state => (
                <option value={state.toLocaleLowerCase()} key={state}>
                  {state}
                </option>
              ))}
            </Select>
          </div>
          <InputText
            type="text"
            label="Bairro"
            id="neighborhood"
            name="neighborhood"
            value={neighborhood}
            onChange={this.changeField}
            highlightRequired={neighborhood ? false : true}
            maxLength={50}
            required
          />
          <div className="md:flex">
            <InputText
              type="text"
              label="Endereço"
              id="street"
              name="street"
              value={street}
              className="flex-1"
              onChange={this.changeField}
              highlightRequired={street ? false : true}
              maxLength={150}
              required
            />
            <InputText
              type="tel"
              label="Número"
              className="md:ml-4 md:w-1/4"
              id="number"
              name="number"
              value={number}
              onChange={this.changeField}
              highlightRequired={number ? false : true}
              maxLength={10}
              required
            />
          </div>
          <InputText
            type="text"
            label="Complemento (opcional)"
            name="complement"
            id="complement"
            value={complement || ''}
            onChange={this.changeField}
            maxLength={150}
          />
          {showErrorMessage && (
            <p className="text-red-400 text-center">{errorMessage}</p>
          )}
          <ButtonGroup className="mx-auto mt-8">
            <Button
              color="info"
              type="submit"
              id="btn-send"
              data-cy="continue-address"
              className="mb-4"
            >
              {text}
            </Button>
            <Link to="/cep" className="button">
              Voltar
            </Link>
          </ButtonGroup>
        </form>
      </div>
    )
  }
}

Address.propTypes = {
  states: PropTypes.array,
  dispatch: PropTypes.func,
  submitForm: PropTypes.func,
  history: PropTypes.object,
  address: PropTypes.object,
  step: PropTypes.number,
  login: PropTypes.object,
  transaction: PropTypes.object,
  user: PropTypes.object,
  stepActive: PropTypes.number,
}

const mapStateToProps = state => state

export default withRouter(connect(mapStateToProps)(Address))
