import React from 'react'
import { connect } from 'react-redux'
import { Icon } from '@yapay/design-system'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import UserLogged from 'components/UserLogged'
import RedirectWithTimer from './RedirectWithTimer'

export const BalancePaid = props => {
  const { transaction } = props.order
  const redirectTime = 10
  const ref = React.useRef()

  const handleLogout = () => {
    if (!ref.current) return

    return ref.current.setCounter(redirectTime)
  }

  return (
    <div>
      <RestoreScoll />
      <h2>Pagamento realizado com sucesso! Transação nº {transaction}</h2>
      {props.redirectUrl && (
        <RedirectWithTimer
          className="mb-8"
          to={props.redirectUrl}
          reference={ref}
        />
      )}

      <UserLogged {...props} onLoggout={handleLogout} />

      <div className="flex items-center my-8">
        <div className="mr-4">
          <Icon
            icon="transfer"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Seu pagamento foi recebido com sucesso pela nossa equipe.
        </p>
      </div>

      <div className="flex items-center my-8">
        <div className="mr-4">
          <Icon
            icon="truck"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Já informamos o vendedor que seu pagamento foi aprovado.
        </p>
      </div>

      <div className="flex items-center">
        <div className="mr-4">
          <Icon
            icon="mail"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Você será informado por e-mail sobre o andamento do pedido.
        </p>
      </div>

      <SellerContact />
    </div>
  )
}

BalancePaid.propTypes = {
  order: PropTypes.object,
  redirectUrl: PropTypes.string,
  redirectBalance: PropTypes.bool,
}

export default connect()(BalancePaid)
