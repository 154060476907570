import { OPEN_CART, CLOSE_CART } from 'actions'

export function addressData(state = { closed: true, state: 'closed' }, action) {
  switch (action.type) {
    case OPEN_CART:
      return {
        ...state,
        closed: false,
        state: 'open',
      }

    case CLOSE_CART:
      return {
        ...state,
        closed: true,
        state: 'closed',
      }

    default:
      return state
  }
}

export default addressData
