import React from 'react'
import { Icon } from '@yapay/design-system'

const Safe = () => (
  <p
    className="flex items-center justify-center mt-4 text-sm md:text-base"
    id="safe"
  >
    <Icon
      icon="shield"
      width="20"
      height="20"
      className="mr-2 fill-current text-gray-600"
    />
    <span className="text-gray-600">Você está em um ambiente protegido</span>
  </p>
)

export default Safe
