import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Option, InputLocked } from '@yapay/design-system'
import PropTypes from 'prop-types'
import RestoreScoll from 'components/RestoreScroll'
import BackLink from 'components/BackLink'

export class ListLogin extends Component {
  render() {
    const { email } = this.props
    return (
      <React.Fragment>
        <RestoreScoll />
        <h2>Identifique-se</h2>
        <p>
          Você já comprou utilizando este e-mail no intermediador{' '}
          <span>Vindi</span>, precisamos validar algumas informações para
          prosseguir:
        </p>
        <InputLocked
          label="email"
          id="email"
          value={email}
          component={
            <BackLink className="input-locked__button hover:text-gray-900">
              Alterar
            </BackLink>
          }
        />
        <p>Para continuar, selecione uma das opções abaixo:</p>
        <Link to="/codigo" className="no-underline">
          <Option>Enviar código de recuperação para seu e-mail</Option>
        </Link>
        <Link to="/login" className="no-underline">
          <Option>Acessar com sua senha</Option>
        </Link>
      </React.Fragment>
    )
  }
}

ListLogin.propTypes = {
  email: PropTypes.string,
}

const mapStateToProps = /* istanbul ignore next */ state => ({
  email: state.user.email,
})

export default withRouter(connect(mapStateToProps)(ListLogin))
