import React from 'react'
import { connect } from 'react-redux'
import { Logo as LogoYapay } from '@yapay/design-system'

export const SITE_YAPAY = 'https://yapay.com.br'

const getUrlSite = seller => {
  if (!seller.logo_url) {
    return SITE_YAPAY
  }

  return seller.url
}

const renderSellerLogo = seller => () => {
  return (
    <div
      className="w-24 h-24 sm:w-32 sm:h-32 sm:my-0 my-2"
      style={{
        background: `url('${seller.logo_url}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
      }}
    />
  )
}

const renderLogo = (LogoComponent, { linkLogoActive, urlSite }) => {
  if (linkLogoActive && urlSite) {
    return (
      <a href={urlSite} target="_blank" rel="noopener noreferrer">
        <LogoComponent />
      </a>
    )
  }

  return <LogoComponent />
}

export const Logo = props => {
  const urlSite = getUrlSite(props.transaction && props.transaction.seller)
  const linkLogoActive = props.linkLogo.active

  if (!props.transaction || !props.transaction.seller.logo_url) {
    return renderLogo(LogoYapay, { linkLogoActive, urlSite })
  }

  return renderLogo(renderSellerLogo(props.transaction.seller), {
    linkLogoActive,
    urlSite,
  })
}

const mapStateToProps = /* istanbul ignore next */ state => state

export default connect(mapStateToProps)(Logo)
