import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'reducers'
import { loadState, saveState } from './sessionStorage'

const persistedState = loadState()
const configureStore = () => {
  const store = createStore(rootReducer, persistedState, applyMiddleware(thunk))

  store.subscribe(() => saveState(store.getState()))

  return store
}

export default configureStore
