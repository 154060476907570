import React from 'react'
import PropTypes from 'prop-types'
import ErrorTrack from './ErrorTrack'
import Routes from './Routes'
import Analytics from './Analytics'
import { Route } from 'react-router-dom'
import { Provider } from 'react-redux'

const Root = ({ store }) => (
  <Provider store={store}>
    <ErrorTrack>
      <Route path="/" component={Analytics} />
      <Routes />
    </ErrorTrack>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
