import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCEP, changeUser, stepActive, cepEmpty, CEP_VALID } from 'actions'
import { Icon, InputCEP, Button, ButtonGroup } from '@yapay/design-system'

export class Cep extends Component {
  changeField = event => {
    const { target } = event
    const { dispatch } = this.props

    dispatch(changeUser({ [target.name]: target.value }))

    if (target.value.length === 0) {
      dispatch(cepEmpty())
    }

    if (target.value.length === 9 && !target.value.includes('_')) {
      this.checkCEP(target.value)
    }
  }

  checkCEP = cep => {
    return this.props.dispatch(getCEP(cep)).then(({ type }) => {
      if (type === CEP_VALID) {
        if (typeof this.props.location.state === 'undefined') {
          return this.props.history.push('/endereco')
        }

        return this.props.history.push('/altera-endereco')
      }

      return null
    })
  }

  submitForm = event => {
    event.preventDefault()
    const { cepValid, checkingCep } = this.props.user
    if (cepValid && !checkingCep) {
      if (typeof this.props.location.state === 'undefined') {
        return this.props.history.push('/endereco')
      }

      return this.props.history.push('/altera-endereco')
    }
  }

  componentDidMount() {
    this.props.dispatch(stepActive(2))
  }

  render() {
    const { cepValid, zip_code, checkingCep, lockCep } = this.props.user || ''

    return (
      <div className="flex-1">
        <h2>Informe seu CEP</h2>

        <form onSubmit={this.submitForm}>
          <div className="relative">
            <InputCEP
              label="CEP"
              id="zip_code"
              name="zip_code"
              value={zip_code}
              valid={cepValid}
              onChange={this.changeField}
              disabled={lockCep}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 50 50"
              fill="#e1e1e1"
              className={`absolute top-0 ${checkingCep ? '' : 'hidden'}`}
              style={{ top: '13px', right: '1rem' }}
            >
              <path d="M43.94 25.14a18.68 18.68 0 1 0-37.37 0h4.07a14.62 14.62 0 0 1 29.23 0h4.07z">
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.4s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
          <a
            href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex no-underline"
          >
            <Icon
              icon="location"
              width="20"
              height="20"
              className="fill-current text-gray-600 mr-2"
            />
            <span className="text-gray-600 hover:text-blue-600">
              Não sei meu CEP
            </span>
          </a>

          <ButtonGroup className="mx-auto mt-8">
            <Button color="info" className="mb-4">
              Continuar
            </Button>
            <Link to="/cadastro" className="button">
              Voltar
            </Link>
          </ButtonGroup>
        </form>
      </div>
    )
  }
}

Cep.propTypes = {
  submitForm: PropTypes.func,
  dispatch: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = /* istanbul ignore next */ state => state

export default withRouter(connect(mapStateToProps)(Cep))
