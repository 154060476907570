import {
  TRANSFER_DID_GENERATE,
  GENERATING_TRANSFER,
  TRANSFER_ERROR,
  TRANSFER_RESET_ERROR_MESSAGE,
} from 'actions'

const text = {
  default: 'Pagar',
  generating: 'Gerando Transferência...',
  redirecting: 'Aguarde o redirecionamento...',
}

export function tef(
  state = {
    generated: false,
    btnText: text.default,
    showMessageError: false,
  },
  action,
) {
  switch (action.type) {
    case TRANSFER_DID_GENERATE:
      return {
        ...state,
        ...action.data,
        generated: true,
        btnText: text.default,
      }

    case GENERATING_TRANSFER:
      return {
        ...state,
        btnText: text.generating,
        showMessageError: false,
      }

    case TRANSFER_ERROR:
      return {
        ...state,
        ...action.data,
        btnText: text.default,
        showMessageError: true,
      }

    case TRANSFER_RESET_ERROR_MESSAGE:
      return {
        ...state,
        showMessageError: false,
      }

    default:
      return state
  }
}

export default tef
