import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDefOrder, ORDER_UNDEFINED, toggleLinkLogo } from 'actions'
import PropTypes from 'prop-types'
import BilletDidGenerated from 'components/BilletDidGenerated'
import BilletPaid from 'components/BilletPaid'
import CardProcessing from 'components/CardProcessing'
import CardPaid from 'components/CardPaid'
import TefDidGenerated from 'components/TefDidGenerated'
import TefPaid from 'components/TefPaid'
import BalancePaid from 'components/BalancePaid'
import PixDidGenerated from 'components/PixDidGenerated'
import PixPaid from 'components/PixPaid'
import BolepixDidGenerated from 'components/BolepixDidGenerated'
import BolepixPaid from 'components/BolepixPaid'

export class Shareable extends Component {
  state = {
    redirectUrl: '',
    user: this.props.user,
  }

  setRedirectUrl() {
    const { url_success, url_process } = this.props.transaction.order
    const { status } = this.state.payment

    if (!url_success && url_process) {
      this.setState({ redirectUrl: url_process })
      return
    }

    if ((status === 'paid' || status === 'approved') && url_success) {
      this.setState({ redirectUrl: url_success })
      return
    }

    if ((status === 'processing' || status === 'generated') && url_process) {
      this.setState({ redirectUrl: url_process })
      return
    }
  }

  componentDidMount() {
    const { dispatch, history, match } = this.props
    dispatch(toggleLinkLogo())
    dispatch(getDefOrder(match.params.id)).then(resp => {
      if (resp.type === ORDER_UNDEFINED) return history.push('/nao-encontrado')
      this.setState({ ...resp.data })
      this.setRedirectUrl()
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(toggleLinkLogo())
  }

  render() {
    if (!this.state.payment) return null

    const { payment_method, status } = this.state.payment

    if (payment_method === 'billet') {
      switch (status) {
        case 'paid':
          return <BilletPaid {...this.state} />

        case 'generated':
          return <BilletDidGenerated {...this.state} />

        default:
          return null
      }
    }

    if (payment_method === 'card') {
      switch (status) {
        case 'approved':
          return <CardPaid {...this.state} />

        case 'processing':
          return <CardProcessing {...this.state} />

        default:
          return null
      }
    }

    if (payment_method === 'tef') {
      switch (status) {
        case 'paid':
          return <TefPaid {...this.state} />

        case 'generated':
          return <TefDidGenerated {...this.state} />

        default:
          return null
      }
    }

    if (payment_method === 'balance' && status === 'approved') {
      const { redirectBalance } = this.props.history.location

      return <BalancePaid {...this.state} redirectBalance={redirectBalance} />
    }

    if (payment_method === 'pix') {
      switch (status) {
        case 'generated':
          return <PixDidGenerated {...this.state} />
        case 'paid':
          return <PixPaid {...this.state} />
        default:
          return null
      }
    }

    if (payment_method === 'bolepix') {
      switch (status) {
        case 'generated':
          return <BolepixDidGenerated {...this.state} />
        case 'paid':
          return <BolepixPaid {...this.state} />
        default:
          return null
      }
    }
  }
}

Shareable.propTypes = {
  match: PropTypes.object,
  transaction: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(Shareable)
