import React, { Component, Suspense } from 'react'
import MediaQuery from 'react-responsive'
import { Well, Divider, Loading } from '@yapay/design-system'

/* istanbul ignore next */
const QrReader = React.lazy(() => import('react-qr-reader'))

export class NotFound extends Component {
  state = {
    delay: 300,
    result: '',
  }

  handleError = error => this.setState({ error })

  handleScan = data => (data ? window.location.assign(data) : null)

  render() {
    return (
      <div>
        <MediaQuery query="(max-width: 991px)">
          <h2 className="mb-0">Ler QR Code</h2>
          <p className="mt-2">
            É necessário dar permissão para acessarmos a câmera e realizar a
            leitura do QR Code
          </p>
          <Suspense
            fallback={
              <div
                className="flex align-center justify-center"
                style={{ minHeight: 288 }}
              >
                <Loading />
              </div>
            }
          >
            <QrReader
              delay={this.state.delay}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%' }}
            />
          </Suspense>
          <Divider />
        </MediaQuery>
        <Well className="p-4 md:p-8">
          <h2 className="mt-0">Nenhuma transação identificada</h2>
          <p>Abaixo listamos alguns motivos que podem causar isto:</p>
          <ul className="mb-0">
            <li>Ter acessado diretamente alguma URL</li>
            <li>Ter aberto um link com formato errado</li>
            <li>Utilizar e-mail diferente do vinculado à transação</li>
          </ul>
        </Well>
      </div>
    )
  }
}

export default NotFound
