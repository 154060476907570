import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OrderTypeCharge from 'components/OrderTypeCharge'
import OrderTypeCart from 'components/OrderTypeCart'
import OrderTypeInvoice from 'components/OrderTypeInvoice'
import 'static/css/Sidebar.css'

export class OrderDetail extends Component {
  render() {
    const { order, seller } = this.props.transaction
    switch (order.type) {
      case 'charge':
        return <OrderTypeCharge order={order} seller={seller} />

      case 'cart':
        return <OrderTypeCart order={order} seller={seller} />

      case 'invoice': {
        const { billing_invoice } = this.props.transaction
        return <OrderTypeInvoice billing={billing_invoice} seller={seller} />
      }

      default: {
        return <OrderTypeCart order={order} seller={seller} />
      }
    }
  }
}

OrderDetail.propTypes = {
  transaction: PropTypes.object.isRequired,
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(OrderDetail)
