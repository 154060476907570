import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RestoreScoll from 'components/RestoreScroll'
import TefOption from 'components/TefOption'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { Option, ButtonGroup } from '@yapay/design-system'
import {
  generateTransfer,
  transferResetErrorMessage,
  TRANSFER_DID_GENERATE,
} from 'actions'

export class TefPayment extends Component {
  state = {
    option: null,
  }

  handleClick = ({ icon, method, text }) => {
    ReactGA.event({
      category: 'TEF Payment',
      action: 'Choose a bank',
    })

    this.setState({
      option: {
        icon,
        method,
        text,
      },
    })
  }

  pay = tokenRecaptcha => {
    if (!tokenRecaptcha) {
      return
    }

    const { generateTransfer, history, payParams } = this.props
    payParams.payment_method = this.state.option.method

    return generateTransfer({
      ...payParams,
      token_recaptcha: tokenRecaptcha,
    }).then(resp => {
      if (resp.type === TRANSFER_DID_GENERATE) {
        ReactGA.event({
          category: 'TEF Payment',
          action: 'TEF Generated',
        })

        return history.replace(`/pagamento/${resp.data.transaction_token}`)
      }

      ReactGA.event({
        category: 'TEF Payment',
        action: 'TEF Fail',
      })

      return null
    })
  }

  render() {
    const {
      orderUndefined,
      paymentMethods,
      btnText,
      showMessageError,
      redirectUrl,
      transferResetErrorMessage,
    } = this.props

    if (orderUndefined) return <Redirect to="/nao-encontrado" />

    ReactGA.event({
      category: 'Choose Payment',
      action: 'TEF',
    })

    if (this.state.option) {
      const props = {
        btnText,
        showMessageError,
        transferResetErrorMessage,
        redirectUrl,
        pay: this.pay,
      }
      return <TefOption {...props} {...this.state.option} />
    }

    return (
      <div className="list-tef">
        <RestoreScoll />
        <h2>Escolha seu banco para realizar o pagamento</h2>
        {paymentMethods.map(type => {
          switch (type.name) {
            case 'transf_itau':
              return (
                <Option
                  icon="tef-itau"
                  name={type.name}
                  key={type.name}
                  onClick={() =>
                    this.handleClick({
                      method: type.name,
                      icon: 'tef-itau',
                      text: 'Itaú',
                    })
                  }
                >
                  Itaú
                </Option>
              )

            case 'transf_bradesco':
              return (
                <Option
                  icon="tef-bradesco"
                  name={type.name}
                  key={type.name}
                  onClick={() =>
                    this.handleClick({
                      method: type.name,
                      icon: 'tef-bradesco',
                      text: 'Bradesco',
                    })
                  }
                >
                  Bradesco
                </Option>
              )

            case 'transf_bb':
              return (
                <Option
                  icon="tef-bb"
                  name={type.name}
                  key={type.name}
                  data-cy="tef-bb"
                  onClick={() =>
                    this.handleClick({
                      method: type.name,
                      icon: 'tef-bb',
                      text: 'Banco do Brasil',
                    })
                  }
                >
                  Banco do Brasil
                </Option>
              )

            default:
              return null
          }
        })}

        <ButtonGroup className="mx-auto mt-12">
          <Link to="/escolha-pagamento" className="button">
            Voltar
          </Link>
        </ButtonGroup>
      </div>
    )
  }
}

TefPayment.propTypes = {
  payParams: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string,
  orderUndefined: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  btnText: PropTypes.string.isRequired,
  showMessageError: PropTypes.bool.isRequired,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      orderUndefined,
      order: {
        token,
        order_token,
        transaction_token,
        url_cancel,
        url_process,
        available_payment_methods: methods,
      },
    },
    user: { address_token, email },
    billet: { isFetching },
    tef: { btnText, showMessageError },
  } = state

  const redirectUrl = url_cancel || url_process || null

  const payParams = {
    token,
    seller_token: state.transaction.seller.token,
    address_token,
    order_token,
    transaction_token,
    payment_retry: state.transaction.paymentRetry,
    email,
  }

  if (state.transaction.type === 't') {
    payParams.customer = {
      name: state.user.name,
      company_name: state.user.company_name,
      email: state.user.email,
      cpf: state.user.cpf,
      cnpj: state.user.cnpj,
      zip_code: state.user.zip_code,
      street: state.user.street,
      number: state.user.number,
      neighborhood: state.user.neighborhood,
      city: state.user.city,
      state: state.user.state,
      phone: state.user.phone,
    }
  }

  if (orderUndefined) return { orderUndefined }

  const paymentMethods = methods.filter(item => item.type === 'tef')[0].values

  return {
    payParams,
    redirectUrl,
    orderUndefined,
    isFetching,
    paymentMethods,
    btnText,
    showMessageError,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { generateTransfer, transferResetErrorMessage },
  )(TefPayment),
)
