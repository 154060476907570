export const initFingerPrint = () => {
  if (window.yapay)
    return window.yapay.FingerPrint({
      env: process.env.REACT_APP_FINGERPRINT_ENV,
    })
}

export const getFingerPrint = () => {
  try {
    return initFingerPrint().getFingerPrint()
  } catch (error) {
    return null
  }
}
