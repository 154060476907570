import {
  LOGIN,
  LOGOUT,
  LOGGED_IN,
  ADDRESS_UPDATED,
  SHOW_ERROR_MESSAGE,
  CHANGE_LOGIN,
  REMOVE_LOGIN_BALANCE,
  USER_REGISTERED,
  USER_UNREGISTERED,
  CHECKING_LOGIN,
  REQUESTS_EXCEEDED,
  SESSION_EXPIRED,
  LOGIN_ERROR,
  REQUEST_IDENTIFICATION,
} from 'actions'

const initialState = {
  checkingRegister: false,
  showErrorMessage: false,
  sessionExpired: false,
  errorMessage: 'E-mail ou senha inválidos.',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const { email, checkingRegister, registered, question } = action.loginInfo

      return {
        ...state,
        email,
        checkingRegister,
        registered,
        question,
      }
    }

    case CHANGE_LOGIN:
      return {
        ...state,
        session_token: null,
        checkingRegister: true,
        showErrorMessage: false,
      }

    case ADDRESS_UPDATED:
      return initialState

    case REQUEST_IDENTIFICATION:
      return {
        ...state,
        showErrorMessage: false,
      }

    case USER_REGISTERED:
      return {
        ...state,
        sessionExpired: false,
        checkingRegister: false,
      }

    case USER_UNREGISTERED:
      return {
        ...state,
        checkingRegister: false,
      }

    case CHECKING_LOGIN:
      return {
        ...state,
        checkingLogin: true,
      }

    case LOGOUT:
      return {
        checkingRegister: false,
        showErrorMessage: false,
      }

    case LOGGED_IN:
      return {
        ...state,
        success: action.data.success,
        session_token: action.data.session_token,
        checkingLogin: false,
        showErrorMessage: false,
        registered: true,
      }

    case LOGIN_ERROR:
      return {
        ...state,
        errorMessage: action.error,
        showErrorMessage: true,
        checkingLogin: false,
      }

    case SHOW_ERROR_MESSAGE:
      return {
        ...state,
        showErrorMessage: action.hide,
        checkingLogin: false,
      }

    case REQUESTS_EXCEEDED:
      return {
        ...state,
        checkingLogin: false,
      }

    case SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      }

    case REMOVE_LOGIN_BALANCE:
      return {
        ...state,
        session_token: null,
      }

    default:
      return state
  }
}
