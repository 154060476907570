import {
  FETCH_BALANCE,
  FETCH_BALANCE_ERROR,
  FETCH_BALANCE_SUCCESS,
  RESET_BALANCE,
  CHANGE_LOGIN,
  LOGGED_IN,
} from 'actions'

const initialState = {
  value: null,
  loading: false,
  error: null,
}

export function balance(state = initialState, action) {
  switch (action.type) {
    case FETCH_BALANCE:
      return {
        ...state,
        error: null,
        loading: true,
      }

    case FETCH_BALANCE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case FETCH_BALANCE_SUCCESS:
      return {
        ...state,
        value: action.payload.available_balance,
        error: null,
        loading: false,
      }

    case RESET_BALANCE:
      return initialState

    case CHANGE_LOGIN:
      return initialState

    case LOGGED_IN:
      return initialState

    default:
      return state
  }
}

export default balance
