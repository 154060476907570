import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const GOOGLE_RECAPTCHA_SITE_KEY = '6LfDA7EUAAAAAHi2Y5i1I532oHjsaohYw2xxKW3X'

const Recaptcha = ({ onChange, invalid, errorMessage }) => {
  const handleChange = /* istanbul ignore next */ token => {
    onChange(token)
  }

  return (
    <div>
      <ReCAPTCHA
        sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
        size="normal"
        onChange={handleChange}
      />
      {invalid && <p className="text-red-400 mt-0 mb-4">{errorMessage}</p>}
    </div>
  )
}

export default Recaptcha
