import React from 'react'
import ReactGA from 'react-ga'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import Clipboard from 'react-clipboard.js'
import MediaQuery from 'react-responsive'
import SellerContact from 'components/SellerContact'
import { connect } from 'react-redux'
import { Icon, ButtonGroup } from '@yapay/design-system'
import RedirectWithTimer from './RedirectWithTimer'

const text = {
  fontWeight: 300,
  lineHeight: 0.9,
}

export function copySuccess(event) {
  ReactGA.event({
    category: 'Billet Payment',
    action: 'Copied Barcode',
  })

  event.trigger.textContent = event.trigger.dataset.successCopy
  setTimeout(() => {
    event.trigger.textContent = event.trigger.dataset.defaultText
  }, 2000)
}

export const BilletDidGenerated = props => {
  return (
    <div>
      <RestoreScoll />
      <h2 data-cy="message-billet-generated">
        Boleto gerado com sucesso! Transação nº {props.order.transaction}
      </h2>
      {props.redirectUrl && <RedirectWithTimer to={props.redirectUrl} />}

      <Icon
        icon="barcode"
        width="140"
        height="90"
        className="fill-current text-gray-600 mx-auto block mb-8 md:mt-12"
      />
      <p className="text-center" style={{ wordBreak: 'break-word' }}>
        {props.payment.digitable_line}
      </p>

      <MediaQuery query="(min-width: 992px)">
        <ButtonGroup responsive>
          <a
            href={props.payment.url_payment}
            className="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visualizar boleto
          </a>
          <Clipboard
            data-clipboard-text={props.payment.digitable_line}
            data-default-text="Copiar código de barras"
            data-success-copy="Copiado!"
            className="button button--info p-0"
            onSuccess={copySuccess}
          >
            Copiar código de barras
          </Clipboard>
        </ButtonGroup>
      </MediaQuery>

      <MediaQuery query="(max-width: 991px)">
        <Clipboard
          data-clipboard-text={props.payment.digitable_line}
          data-default-text="Copiar código de barras"
          data-success-copy="Copiado!"
          className="button button--info mb-4 w-full"
          onSuccess={copySuccess}
        >
          Copiar código de barras
        </Clipboard>
        <a
          href={props.payment.url_payment}
          className="button w-full"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visualizar boleto
        </a>
      </MediaQuery>

      <div className="flex items-center my-8">
        <div className="mr-4 relative">
          <Icon
            icon="days"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
            <span className="text-gray-600 text-2xl" style={text}>
              2
            </span>
            <span className="text-gray-600 text-sm" style={text}>
              dias
            </span>
          </div>
        </div>
        <p className="m-0">
          A confirmação do pagamento será feita de forma <wbr />
          automática no prazo de até 2 dias úteis.
        </p>
      </div>

      <div className="flex items-center">
        <div className="mr-4">
          <Icon
            icon="truck"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          O envio ou serviço somente será realizado após a aprovação da
          transação.
        </p>
      </div>

      <SellerContact />
    </div>
  )
}

BilletDidGenerated.propTypes = {
  transaction: PropTypes.object,
  seller: PropTypes.object,
  order: PropTypes.object,
  payment: PropTypes.object,
  barcode: PropTypes.string,
  link: PropTypes.string,
  redirectUrl: PropTypes.string,
}

export default connect()(BilletDidGenerated)
