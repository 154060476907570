import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Steps } from '@yapay/design-system'

export const StepsContainer = props => {
  if (typeof props.location === 'undefined') return null
  const { pathname } = props.location

  if (
    pathname.indexOf('/pagamento/') > -1 ||
    pathname.indexOf('nao-encontrado') > -1
  )
    return null

  return (
    <Steps
      stepActive={props.stepActive}
      className="m-0 md:my-4 md:mb-12 md:mt-0"
      steps={['Identifique-se', 'Endereço', 'Pagamento']}
      id="steps"
    />
  )
}

StepsContainer.propTypes = {
  location: PropTypes.object,
  stepActive: PropTypes.number,
}

const mapStateToProps = state => state

export default withRouter(connect(mapStateToProps)(StepsContainer))
