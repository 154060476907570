import { BILLET_DID_GENERATE, GENERATING_BILLET, BILLET_ERROR } from 'actions'

export function billet(
  state = {
    isFetching: false,
    generated: false,
  },
  action,
) {
  switch (action.type) {
    case BILLET_DID_GENERATE:
      return {
        ...state,
        ...action.data,
        isFetching: false,
        generated: true,
      }

    case BILLET_ERROR:
      return {
        ...state,
        ...action.data,
        isFetching: false,
        generated: false,
      }

    case GENERATING_BILLET:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return state
  }
}

export default billet
