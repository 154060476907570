import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { withRouter, Redirect } from 'react-router-dom'
import Auth from 'components/Auth'
import { Loading } from '@yapay/design-system'
import { addressRequest, loginUpdate, LOGIN_ERROR } from 'actions'

export class AuthCallback extends Component {
  state = {
    updateLoginSuccess: false,
  }

  loginUpdate(data) {
    return this.props.dispatch(loginUpdate(data)).then(resp => {
      if (resp.type === LOGIN_ERROR) {
        return this.props.history.push('/escolha-pagamento')
      }

      ReactGA.event({
        category: 'Identification',
        action: 'User login to pay with balance',
      })

      Auth.signIn(resp.data.session_token)
      this.setState({ updateLoginSuccess: true })
    })
  }

  redirectAddress(session_token) {
    return this.props.dispatch(addressRequest(session_token)).then(() => {
      this.props.history.push('/altera-cep')
    })
  }

  componentDidMount() {
    const data = {
      session_id: this.props.match.params.token,
      token_account: this.props.seller_token,
    }

    this.loginUpdate(data)
  }

  render() {
    if (this.state.updateLoginSuccess) {
      if (this.props.stepActive > 2) return <Redirect to="/pagamento-saldo" />

      this.redirectAddress(this.props.session_token)
    }

    return (
      <div className="text-center">
        <Loading />
      </div>
    )
  }
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      order: { transaction_token },
      seller: { token: seller_token },
    },
    user: { session_token, zip_code },
    stepActive,
  } = state

  return {
    stepActive,
    seller_token,
    session_token,
    transaction_token,
    zip_code,
  }
}

export default withRouter(connect(mapStateToProps)(AuthCallback))
