import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

export const BackLink = ({ token, type, className, children }) => {
  let url = `/transacao/${token}`

  if (typeof type !== 'undefined') url = url + `/${type}`

  return (
    <Link to={url} className={className}>
      {children}
    </Link>
  )
}

BackLink.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  transaction: PropTypes.shape({
    type: PropTypes.string,
    order: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }),
}

const mapStateToProps = /* istanbul ignore next */ state => ({
  token: state.transaction.order.token,
  type: state.transaction.type,
})

export default withRouter(connect(mapStateToProps)(BackLink))
