import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: 'https://c13b655ed61e4328bf8c7d980b2dbb63@sentry.io/1331863',
})

class ErrorTrack extends Component {
  componentDidCatch(error, errorInfo) {
    console.log(error)
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

ErrorTrack.propTypes = {
  children: PropTypes.any,
}

export default ErrorTrack
