import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, Redirect, withRouter } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import RestoreScoll from 'components/RestoreScroll'
import { Option, Logo } from '@yapay/design-system'
import { stepActive } from 'actions'
import Currency from 'components/Currency'
import imgPix from 'static/img/pix.svg'
import imgBolepix from 'static/img/bolepix.svg'

export class ListPayment extends Component {
  splitText = maxSplit => {
    switch (maxSplit) {
      case '1':
        return 'em até 1 vez'

      case '':
        return 'em até 12 vezes'

      default:
        return `em até ${maxSplit} vezes`
    }
  }

  renderBalanceOption = () => {
    const { location, totalBalance } = this.props

    if (location.state && location.state.balanceNegative) {
      return (
        <Option color="danger">
          <div className="flex items-center">
            <div
              style={{
                flex: '0 1 50px',
                filter: 'grayscale(1)',
                opacity: '.6',
              }}
              className="icon flex pt-1 items-center"
            >
              <Logo />
            </div>
            <span>Saldo Vindi</span>
            <span className="ml-4 text-red-600">
              <Currency value={totalBalance} />
            </span>
          </div>
        </Option>
      )
    }

    return (
      <Option>
        <div className="flex items-center">
          <div
            style={{ flex: '0 1 50px', filter: 'grayscale(1)', opacity: '.6' }}
            className="icon flex pt-1 items-center"
          >
            <Logo />
          </div>
          <span>Saldo Vindi</span>
        </div>
      </Option>
    )
  }

  renderPixOption = () => {
    return (
      <Option>
        <div className="flex items-center">
          <div className="icon flex justify-center items-center">
            <img src={imgPix} alt="Pix" width="auto" height="35" />
          </div>
          <span>Pix</span>
        </div>
      </Option>
    )
  }

  renderBolepixOption = () => {
    return (
      <Option>
        <div className="flex items-center">
          <div className="icon flex justify-center items-center">
            <img src={imgBolepix} alt="Bolepix" width="auto" height="35" />
          </div>
          <span>Bolepix</span>
        </div>
      </Option>
    )
  }

  renderTitle = () => {
    const { location } = this.props

    if (location.state && location.state.balanceNegative) {
      return <h2>Saldo insuficiente! Escolha outra forma de pagamento</h2>
    }

    if (this.props.login.showErrorMessage) {
      return (
        <h2>
          <span>{this.props.login.errorMessage}! </span>
          Escolha outra forma de pagamento
        </h2>
      )
    }
  }

  componentDidMount() {
    this.props.dispatch(stepActive(3))
  }

  render() {
    const {
      maxSplit,
      orderUndefined,
      paymentMethods,
      continueWithPost,
      transaction,
    } = this.props

    if (orderUndefined) return <Redirect to="/nao-encontrado" />

    return (
      <div className="list-payment">
        <RestoreScoll />
        {this.renderTitle()}
        {paymentMethods.map(payment => {
          const { type } = payment
          switch (type) {
            case 'billet':
              return (
                <Link
                  to="/pagamento-boleto"
                  key={type}
                  className="no-underline"
                  data-cy="payment-billet"
                >
                  <Option icon="barcode">Boleto</Option>
                </Link>
              )

            case 'bolepix':
              return (
                <Link
                  to="/pagamento-bolepix"
                  key={type}
                  color="danger"
                  className="no-underline"
                >
                  {this.renderBolepixOption()}
                </Link>
              )

            case 'card':
              return (
                <Link
                  to="/pagamento-cartao"
                  key={type}
                  className="no-underline"
                  data-cy="payment-card"
                >
                  <Option
                    icon="card"
                    className="items-start"
                    style={{ lineHeight: 1 }}
                  >
                    <span>Cartão de Crédito</span>
                    <div style={{ lineHeight: 0.7 }}>
                      <small>({this.splitText(maxSplit)})</small>
                    </div>
                  </Option>
                </Link>
              )

            case 'tef':
              if (paymentMethods.length >= 2) {
                return (
                  <MediaQuery query="(min-width: 768px)" key={type}>
                    <Link
                      to="/transferencia"
                      key={type}
                      className="no-underline"
                      data-cy="payment-tef"
                    >
                      <Option icon="transfer2">Transferência Online</Option>
                    </Link>
                  </MediaQuery>
                )
              }

              return (
                <Link to="/transferencia" className="no-underline" key={type}>
                  <Option icon="transfer2">Transferência Online</Option>
                </Link>
              )

            case 'pix':
              return (
                <Link
                  to="/pagamento-pix"
                  key={type}
                  color="danger"
                  className="no-underline"
                >
                  {this.renderPixOption()}
                </Link>
              )

            default:
              return null
          }
        })}

        <div className="text-center mt-8">
          {continueWithPost || transaction.type === 't' ? (
            <Link to="/endereco" className="button w-full md:w-auto">
              Voltar
            </Link>
          ) : (
            <Link to="/confirma-endereco" className="button w-full md:w-auto">
              Voltar
            </Link>
          )}
        </div>
      </div>
    )
  }
}

ListPayment.propTypes = {
  maxSplit: PropTypes.string,
  orderUndefined: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.array,
  continueWithPost: PropTypes.bool,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      orderUndefined,
      order: {
        max_split_transaction: maxSplit,
        available_payment_methods: paymentMethods,
      },
    },
    user: { continueWithPost },
    login,
  } = state

  return {
    totalBalance: state.balance.value,
    orderUndefined,
    maxSplit,
    paymentMethods,
    continueWithPost,
    login,
    transaction: state.transaction,
  }
}

export default withRouter(connect(mapStateToProps)(ListPayment))
