import React from 'react'
import { connect } from 'react-redux'
import { Icon, ButtonGroup } from '@yapay/design-system'
import { Link } from 'react-router-dom'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import RedirectWithTimer from './RedirectWithTimer'

export const BilletFail = props => {
  return (
    <div>
      <RestoreScoll />
      <h2 className="text-blue-500">Não foi possível gerar o boleto!</h2>
      {props.redirectUrl && (
        <RedirectWithTimer className="mb-8" to={props.redirectUrl} />
      )}

      <div className="flex items-center mb-8">
        <div className="mr-4">
          <Icon
            icon="billet-fail"
            width="67"
            height="67"
            className="fill-current text-gray-600"
          />
        </div>
        <p className="m-0">
          Infelizmente não conseguimos realizar a geração do boleto para
          pagamento.
        </p>
      </div>

      <ButtonGroup responsive>
        <Link to="/escolha-pagamento" className="button px-0">
          Utilizar outra forma de pagamento
        </Link>

        <Link to="/pagamento-boleto" className="button button--info px-0">
          Tentar novamente
        </Link>
      </ButtonGroup>

      <SellerContact />
    </div>
  )
}

BilletFail.propTypes = {
  seller: PropTypes.object,
  redirectUrl: PropTypes.string,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      order: { url_cancel, url_process },
    },
  } = state

  const redirectUrl = url_cancel || url_process || null

  return { redirectUrl }
}

export default connect(mapStateToProps)(BilletFail)
