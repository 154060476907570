import React from 'react'
import { connect } from 'react-redux'
import { ButtonGroup } from '@yapay/design-system'
import { Link } from 'react-router-dom'
import RestoreScoll from 'components/RestoreScroll'
import PropTypes from 'prop-types'
import SellerContact from 'components/SellerContact'
import RedirectWithTimer from './RedirectWithTimer'

export const PixFail = props => {
  return (
    <div>
      <RestoreScoll />
      <h2 className="text-blue-500 mb-4">Não foi possível gerar o QR Code!</h2>
      {props.redirectUrl && (
        <RedirectWithTimer className="mb-8" to={props.redirectUrl} />
      )}

      <div className="mb-8">
        <p className="m-0">
          {props.pix.error ? (
            <>
              {props.pix.error}. Entre em contato com nossa{' '}
              <a
                href={props.pix.link_contact_us}
                target="_blank"
                rel="noopener noreferrer"
              >
                central de atendimento
              </a>{' '}
              e informe o código {props.pix.error_code[0]}.
            </>
          ) : (
            ' Infelizmente não conseguimos realizar a geração do QR Code para pagamento.'
          )}
        </p>
      </div>

      <ButtonGroup responsive>
        <Link to="/escolha-pagamento" className="button px-0">
          Utilizar outra forma de pagamento
        </Link>

        <Link to="/pagamento-pix" className="button button--info px-0">
          Tentar novamente
        </Link>
      </ButtonGroup>

      <SellerContact />
    </div>
  )
}

PixFail.propTypes = {
  seller: PropTypes.object,
  redirectUrl: PropTypes.string,
}

const mapStateToProps = /* istanbul ignore next */ state => {
  const {
    transaction: {
      order: { url_cancel, url_process },
    },
    pix,
  } = state

  const redirectUrl = url_cancel || url_process || null

  return { pix, redirectUrl }
}

export default connect(mapStateToProps)(PixFail)
