import { combineReducers } from 'redux'
import login from 'reducers/Login'
import stepActive from 'reducers/Steps'
import transaction from 'reducers/Transaction'
import question from 'reducers/Question'
import billet from 'reducers/Billet'
import passwordRecovery from 'reducers/PasswordRecovery'
import cart from 'reducers/Cart'
import user from 'reducers/User'
import tef from 'reducers/Tef'
import balance from 'reducers/Balance'
import pix from 'reducers/Pix'
import bolepix from 'reducers/Bolepix'
import linkLogo from 'reducers/LinkLogo'

export default combineReducers({
  stepActive,
  login,
  transaction,
  question,
  billet,
  passwordRecovery,
  cart,
  user,
  tef,
  balance,
  pix,
  bolepix,
  linkLogo,
})
