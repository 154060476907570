import {
  BOLEPIX_DID_GENERATE,
  GENERATING_BOLEPIX,
  BOLEPIX_ERROR,
} from 'actions'

export function bolepix(
  state = {
    isFetching: false,
    generated: false,
  },
  action,
) {
  switch (action.type) {
    case BOLEPIX_DID_GENERATE:
      return {
        ...state,
        ...action.data,
        isFetching: false,
        generated: true,
      }

    case BOLEPIX_ERROR:
      return {
        ...state,
        ...action.data,
        isFetching: false,
        generated: false,
      }

    case GENERATING_BOLEPIX:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return state
  }
}

export default bolepix
