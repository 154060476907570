import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MediaQuery from 'react-responsive'
import Safe from 'components/Safe'
import Header from 'components/Header'
import Cep from 'components/Cep'
import NotFound from 'components/NotFound'
import Address from 'components/Address'
import AddressUpdate from 'components/AddressUpdate'
import BilletFail from 'components/BilletFail'
import CardFail from 'components/CardFail'
import CardPayment from 'components/CardPayment'
import BalancePayment from 'components/BalancePayment'
import AuthCallback from 'components/AuthCallback'
import BalanceFail from 'components/BalanceFail'
import TefPayment from 'components/TefPayment'
import Steps from 'components/Steps'
import Sidebar from 'components/Sidebar'
import Identification from 'components/Identification'
import LoginWithPassword from 'components/LoginWithPassword'
import ListPayment from 'components/ListPayment'
import Shareable from 'components/Shareable'
import BilletPayment from 'components/BilletPayment'
import SignUp from 'components/SignUp'
import LoginWithCode from 'components/LoginWithCode'
import ListLogin from 'components/ListLogin'
import Private from 'components/PrivateRoute'
import PixPayment from 'components/PixPayment'
import BolepixPayment from 'components/BolepixPayment'
import PixFail from 'components/PixFail'
import BolepixFail from 'components/BolepixFail'
import { checkAlreadyLogged } from 'components/Auth'
import 'static/css/App.css'

checkAlreadyLogged()

const Routes = props => (
  <div
    className={`app flex justify-between ${props.cart.closed ? '' : 'overlay'}`}
  >
    <div className="flex flex-col flex-1">
      <Route path="/" component={Header} />
      <main className="flex-1 flex flex-col px-4 pb-4 md:pb-10 md:px-10 md:mt-8 md:mx-auto">
        <Route path="/" component={Steps} />
        <Switch>
          <Route path="/transacao/:token/:type?" component={Identification} />
          <Route path="/login" component={LoginWithPassword} />
          <Route path="/codigo" component={LoginWithCode} />
          <Route path="/escolha-login" component={ListLogin} />
          <Route path="/cadastro" component={SignUp} />
          <Route path="/cep" component={Cep} />
          <Route path="/endereco" component={AddressUpdate} />
          <Route path="/confirma-endereco" component={Address} />
          <Route path="/pagamento/:id" component={Shareable} />
          <Route path="/nao-encontrado" component={NotFound} />
          <Route path="/escolha-pagamento" component={ListPayment} />
          <Route path="/pagamento-boleto" component={BilletPayment} />
          <Route path="/pagamento-cartao" component={CardPayment} />
          <Route path="/auth/callback/:token" component={AuthCallback} />
          <Route path="/pagamento-saldo" component={BalancePayment} />
          <Route path="/pagamento-pix" component={PixPayment} />
          <Route path="/pagamento-bolepix" component={BolepixPayment} />
          <Route path="/transferencia" component={TefPayment} />
          <Route path="/boleto-falhou" component={BilletFail} />
          <Route path="/cartao-falhou" component={CardFail} />
          <Route path="/saldo-falhou" component={BalanceFail} />
          <Route path="/pix-falhou" component={PixFail} />
          <Route path="/bolepix-falhou" component={BolepixFail} />

          <Private path="/altera-cep" component={Cep} />
          <Private path="/altera-endereco" component={AddressUpdate} />

          <Redirect exact from="/transacao" push to="/nao-encontrado" />
          <Redirect from="/" to="/nao-encontrado" />
        </Switch>
        <MediaQuery query="(max-width: 1024px)">
          <Route path="/" component={Safe} />
        </MediaQuery>
      </main>
    </div>
    <Route path="/" component={Sidebar} />
    <ToastContainer />
  </div>
)

Routes.propTypes = {
  cart: PropTypes.object,
}

const mapStateToProps = state => state

export default withRouter(connect(mapStateToProps)(Routes))
