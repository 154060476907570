import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Auth from 'components/Auth'
import ReactGA from 'react-ga'
import BackLink from 'components/BackLink'
import Recaptcha from 'components/Recaptcha'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { stepActive, LOGIN_ERROR } from 'actions'
import {
  InputPassword,
  InputLocked,
  Button,
  ButtonGroup,
  InputEmail,
} from '@yapay/design-system'
import {
  login,
  showErrorMessage,
  recoverPassword,
  addressRequest,
} from 'actions'

const linkStyle = {
  color: '#868686',
  textDecoration: 'none',
  minWidth: '200px',
}

export class LoginWithPassword extends Component {
  state = {
    tokenRecaptcha: '',
    dirtyLoginButton: false,
  }

  submitForm = event => {
    event.preventDefault()

    this.setState({ dirtyLoginButton: true })

    if (!this.state.tokenRecaptcha) {
      return
    }

    const {
      location: { state },
      transaction: {
        seller: { token },
      },
      dispatch,
    } = this.props

    const { tokenRecaptcha } = this.state

    const formData = new FormData(event.target)
    formData.append('seller_token', token)
    formData.append('token_recaptcha', tokenRecaptcha)

    dispatch(showErrorMessage(false))

    return dispatch(login(formData)).then(({ type, data }) => {
      if (type === LOGIN_ERROR) return null

      const { session_token } = data

      ReactGA.event({
        category: 'Identification',
        action: 'User LoggedIn',
      })

      Auth.signIn(session_token)
      Auth.isAuthenticated = true

      // only allowed for tmp transactions
      if (state && state.changeAddress) {
        ReactGA.event({
          category: 'Identification',
          action: 'User clicked to change address',
        })

        return dispatch(addressRequest(session_token)).then(() => {
          this.props.history.push('/altera-cep')
        })
      }

      // login to pay with balance, redirect to balance page
      if (state && state.balancePayment) {
        ReactGA.event({
          category: 'Identification',
          action: 'User login to pay with balance',
        })

        return this.props.history.push('/pagamento-saldo')
      }

      return session_token
    })
  }

  linkRecoverPassword = event => {
    event.preventDefault()

    ReactGA.event({
      category: 'Identification',
      action: 'User forget password',
    })

    return this.props.dispatch(recoverPassword(this.props.user.email))
  }

  handleChangeRecaptcha = tokenRecaptcha => this.setState({ tokenRecaptcha })

  componentDidMount() {
    this.props.dispatch(stepActive(1))
  }

  render() {
    if (this.props.transaction.orderUndefined) {
      return <Redirect to="/nao-encontrado" />
    }

    const { passwordRecovery } = this.props
    const { showErrorMessage, checkingLogin, errorMessage } = this.props.login

    return (
      <div>
        <h2>Identifique-se</h2>
        <p>
          Este e-mail já possui uma conta no intermedadiador <span>Vindi</span>,
          informe sua senha para continuar:
        </p>

        <form onSubmit={this.submitForm}>
          {this.props.user.email ? (
            <InputLocked
              label="E-mail"
              value={this.props.user.email}
              name="email"
              id="email"
              component={
                <BackLink className="input-locked__button hover:text-gray-900">
                  Alterar
                </BackLink>
              }
            />
          ) : (
            <InputEmail label="E-mail" id="email" name="email" />
          )}
          <InputPassword label="Senha" id="password" name="password" />

          <div className="md:flex justify-end mb-4">
            {showErrorMessage && (
              <p className="text-red-400 text-center md:text-left mr-auto my-0 flex-1">
                {errorMessage}
              </p>
            )}

            <a
              onClick={this.linkRecoverPassword}
              href="/"
              className="block text-center md:text-right no-underline mt-4 sm:mt-0"
              id="recovery-link"
              style={linkStyle}
            >
              {passwordRecovery.text}
            </a>
          </div>

          <ButtonGroup className="my-8 mx-auto">
            <div className="flex justify-center">
              <Recaptcha
                onChange={this.handleChangeRecaptcha}
                invalid={
                  !this.state.tokenRecaptcha && this.state.dirtyLoginButton
                }
                errorMessage="Confirme o reCAPTCHA para continuar"
              />
            </div>

            <Button
              color="info"
              type="submit"
              className="mb-4"
              disabled={this.props.transaction.orderUndefined}
            >
              {checkingLogin ? 'Carregando...' : 'Entrar'}
            </Button>
            <BackLink className="button">Voltar</BackLink>
          </ButtonGroup>
        </form>
      </div>
    )
  }
}

LoginWithPassword.propTypes = {
  email: PropTypes.string,
  submitForm: PropTypes.func,
  history: PropTypes.object,
  login: PropTypes.object,
  dispatch: PropTypes.func,
  passwordRecovery: PropTypes.object,
  user: PropTypes.object,
  transaction: PropTypes.object,
  stepActive: PropTypes.number,
}

const mapStateToProps = /* istanbul ignore next */ state => state

export default withRouter(connect(mapStateToProps)(LoginWithPassword))
